import React, { useState, useEffect } from 'react';
import s from "./HistoryPage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import Button from '@mui/material/Button';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import HistoryForm from '../../../components/Admin/History/HistoryForm/HistoryForm';

const HistoryPage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="История" description="Управление событиями театра" robots="none" />} />
				{/* <Route path="add" element={<HelmetUI title="Добавить событие" description="Добавление нового события" robots="none" />} />
				<Route path=":id" element={<HelmetUI title="Редактирование событие" description="Редактирование события" robots="none" />} /> */}
			</Routes>

			<Routes>
				<Route index element={<MainTitle>История</MainTitle>} />
				{/* <Route path=":id" element={<MainTitle back>Редактировать событие</MainTitle>} />
				<Route path="add" element={<MainTitle back>Добавить событие</MainTitle>} /> */}
			</Routes>

			{/* <Routes>
				<Route index element={
					<div className={s.add}>
						<Link to='./add'>
							<Button sx={{ borderRadius: '0px' }} variant="outlined">Добавить</Button>
						</Link>
					</div>
				} />
			</Routes> */}


			<div className={s.content}>
				<Routes>
					<Route index element={<HistoryForm g1={true}/>} />
					{/* <Route path="add" element={<NewsForm />} />
					<Route path=":id" element={<NewsForm g1={true} />} /> */}
				</Routes>
			</div>
		</>
	);
}

export default HistoryPage;
