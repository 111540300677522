import React from 'react';
import s from './NewsListItem.module.scss'
import { format } from 'date-fns'

const NewsListItem = ({ data }) => {
	// //console.log(data)

	return (
		<div className={s.line} >
			<div className={s.fio}>{data?.title}</div>
			<div className={s.login}>
				{format(new Date(data?.newsDate), 'dd.MM.yyyy в HH:mm')}
			</div>
		</div>
	);
}

export default NewsListItem;
