import React, { useState, useEffect } from 'react';
import s from './InputFileUI.module.scss'
import Button from '@mui/material/Button';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const InputFileUI = ({ file, setFile, multiple, name }) => {

	const [preview, setPreview] = useState({});
	const [showPrevie, setShowPrevie] = useState(false);

	console.log(preview);

	useEffect(() => {
		setPreview(file);
	}, [file, setFile]);



	//Загрузка файлов
	function handleUpload(event) {
		const arrayJob = preview;
		const dt = new DataTransfer();
		for (let i = 0; i < arrayJob.length; i++) {
			dt.items.add(arrayJob[i])
		}

		for (let i = 0; i < event.target.files.length; i++) {
			dt.items.add(event.target.files[i])
		}

		setPreview(dt.files);
		setFile(dt.files);
	}

	//Удалить документ
	function deleteDoc(id) {
		const arrayJob = preview;
		const dt = new DataTransfer();
		for (let i = 0; i < arrayJob.length; i++) {
			if (Number(id) !== Number(i)) {
				//console.log(Number(id) !== Number(i))
				dt.items.add(arrayJob[i])
			}
		}
		setPreview(dt.files);
		setFile(dt.files);
	}

	const list = Object.keys(preview).map((key, index) => {
		// //console.log(preview[key]);
		// //console.log(URL.createObjectURL(preview[key]));
		
		return (

			<div className={s.imgItem} key={index}>
				<img
					className={s.img}
					src={URL.createObjectURL(preview[key])} />
				<ClearOutlinedIcon sx={{ cursor: 'pointer', position: 'absolute', top: '3px', right: '3px', padding: '3px', backgroundColor: '#fff', borderRadius: '50%', border: '1px solid #37246a', color: '#37246a' }} onClick={() => deleteDoc(key)} />
			</div>
		)


	})

	return (
		<div>
			<label htmlFor={'picture' + name}>
				<input
					style={{ display: 'none' }}
					type="file"
					id={'picture' + name}
					name={name ? name : 'picture[]'}
					className={s.inputFile}
					multiple={multiple ? true : false}
					accept="image/*, .jpg, .jpeg, .png, .webp"
					onChange={handleUpload} />
				<Button sx={{ borderRadius: '0px' }} variant="outlined" component="span">Выбрать файл</Button>
			</label>


			<div className={s.imageContainer}>
				{list}
			</div>
		</div>
	);
}

export default InputFileUI;
