import React, { useEffect, useState } from 'react';
import s from "./AfishaForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";

import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import PerformanceAPI from '../../../../utils/PerformanceAPI';
import EventAPI from '../../../../utils/EventAPI';


const inflection = require('inflection')


const AfishaForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState([]);

	const { id } = useParams()
	const navigate = useNavigate();
	const { control, handleSubmit, formState: { errors, isDirty, isValid }, setValue, getValues } = useForm(
		{
			mode: "onSubmit"
		}
	);

	useEffect(() => {
		PerformanceAPI.getPerformance()
			.then(result => {
				// //console.log(result.data);
				if (result.status === 200) {
					setShow(result.data)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	useEffect(() => {
		g1 &&
			EventAPI.getEventForId(id)
				.then(result => {
					// //console.log(result.data[0]);
					if (result.status === 200) {
						setData(result.data[0])
						setLoading(true)
					}

					if (result.response?.status === 500) {
						// //console.log(result.response.data)
					}
				})

		g1 === undefined && setLoading(true)

	}, [g1]);

	useEffect(() => {
		setValue('eventID', nanoid());
	}, [setValue, data, loading]);

	//Добавить событие
	const add = (form) => {

		form.soldOut = form.soldOut === true ? 1 : 0;

		show.map(((item) => {
			if (item.showID === getValues('performance')) {
				form.performanceName = item.name
			}
		}))

		console.log(form)
		EventAPI.addEvent(form)
			.then(result => {
				console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					//console.log(result.response.data)
				}
			})
	}

	//console.log(data)

	//Изменить событие
	const update = (form) => {

		form.soldOut = form.soldOut === true ? 1 : 0;

		show.map(((item) => {
			if (item.showID === getValues('performance')) {
				form.performanceName = item.name
			}
		}))

		//console.log(form)
		EventAPI.updateEvent(id, form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Удалить событие
	const deletePerformance = () => {
		// //console.log('Delete')
		EventAPI.deleteEvent(id)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={
						g1 ? handleSubmit(update) : handleSubmit(add)
					}>

					<Controller
						name="performance"
						control={control}
						defaultValue={data?.performance ? data?.performance : ''}
						render={({ field }) =>
							<FormControl fullWidth error={errors?.performance?.message ? true : false}>
								<InputLabel>Спектакль</InputLabel>
								<Select
									{...field}
									label="Спектакль"
								>
									{show.map(((item) => {
										return <MenuItem key={item?.showID} value={item?.showID}>{item?.name.toUpperCase()}</MenuItem>
									}))}

								</Select>
								<FormHelperText>{errors?.performance?.message ? errors?.performance?.message : ' '}</FormHelperText>
							</FormControl>
						}
						rules={{
							required: 'Поле обязательно для заполнения',
						}}
					/>

					<Controller
						name="eventDate"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								size="small"
								type='datetime-local'
								InputLabelProps={{ shrink: true }}
								label={"Дата проведения:"}
								error={errors?.eventDate?.message && true}
								helperText={errors?.eventDate?.message ? errors?.eventDate?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения',
						}}
						control={control}
						defaultValue={data?.eventDate ? data?.eventDate : ''}
					/>

					<Controller
						name="eventDateTransfer"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								size="small"
								type='datetime-local'
								InputLabelProps={{ shrink: true }}
								label={"Перенос с даты:"}
								error={errors?.eventDateTransfer?.message && true}
								helperText={errors?.eventDateTransfer?.message ? errors?.eventDateTransfer?.message : ' '} />}
						
						control={control}
						defaultValue={data?.eventDateTransfer ? data?.eventDateTransfer : ''}
					/>

					<Controller
						name="ticketland"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label={"Ссылка для покупки билетов"}
								size="small"
								fullWidth
								error={errors?.ticketland?.message && true}
								helperText={errors?.ticketland?.message ? errors?.ticketland?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения'
						}}
						control={control}
						defaultValue={data?.ticketland ? data?.ticketland : ''}
					/>

					<Controller
						name="soldOut"
						render={({ field }) =>
							<>
								<FormControlLabel control={
									<Checkbox
										{...field}
										defaultChecked={data?.soldOut == 1 ? true : false}
									/>
								} label="Билеты проданы" />
							</>
						}
						control={control}
						//defaultValue={data?.ticketland ? data?.ticketland : ''}
					/>

					<div className={s.button}>
						<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
							<>Назад</>
						</Button>

						{g1 !== undefined && <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => deletePerformance(id)}>Удалить</Button>}
						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Создать</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default AfishaForm;
