import React, { useState, useEffect } from 'react';
import s from './SoundramaPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Routes, Route } from "react-router-dom";
import PageAPI from '../../utils/PageAPI';
import SoundramaSite from '../../components/SoundramaSite/SoundramaSite';

const SoundramaPageSite = () => {

	// const [data, setData] = useState([]);
	// const [loading, setLoading] = useState(false);

	// useEffect(() => {
	// 	PageAPI.getHistory()
	// 		.then(result => {
	// 			// //console.log(result.data);
	// 			if (result.status === 200) {
	// 				setData(result.data[0])
	// 				// setImgArray(result.data[0].picture)
	// 				setLoading(true)
	// 			}

	// 			if (result.response?.status === 500) {
	// 				// //console.log(result.response.data)
	// 			}
	// 		})
	// }, []);

	// //console.log(data)
	return (
		<div>
			<div className={s.container}>
				<Routes>
					<Route index element={<HelmetUI title="SounDrama - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
				</Routes>

				<div className={s.content}>
					<SoundramaSite />

				</div>
			</div>
		</div >
	);
}

export default SoundramaPageSite;
