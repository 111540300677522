import React from 'react';
import s from './SubTitle.module.scss'

const SubTitle = ({children}) => {
	return (
		<div className={s.subTitle}>
			{children}
		</div>
	);
}

export default SubTitle;
