import React, { useEffect } from 'react';
import s from './Search.module.scss'
import TextField from '@mui/material/TextField';
import { useForm, Controller } from "react-hook-form";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const Search = ({setSearchRequest}) => {
	// const [searchRequest, setsearchRequest] = useState('');
	

	useEffect(() => {

		const keyDownHandler = event => {
			if (event.key === 'Enter') {
				handleSubmit((data) => setSearchRequest(data.search))
			}
		};

		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	});

	const { control, handleSubmit} = useForm({mode: "onSubmit"});

	return (
		
			<div className={s.search}>
				<form className={s.form} onSubmit={handleSubmit((data) => setSearchRequest(data.search))}>
					<Controller
						render={({ field }) => <TextField sx={{label: {fontSize: '12px'}, input: {p:0}}} {...field} variant="standard" label="Поиск" size="small" fullWidth />}
						name="search"
						control={control}
						defaultValue=""
					/>
					<IconButton type="submit" sx={{ p: '1px', borderRadius: '0px', border: '1px solid #ddd', marginTop: '16px' }} aria-label="search">
						<SearchIcon sx={{fontSize: '18px'}}/>
					</IconButton>
				</form>
			</div>
		
	);
}

export default Search;
