import React, { useState, useEffect } from 'react';
import s from './ContactPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Routes, Route } from "react-router-dom";
import PageAPI from '../../utils/PageAPI';
import EmployeesAPI from './../../utils/EmployeesAPI';
import VacnciesPageSite from '../VacnciesPageSite/VacnciesPageSite';
import SubTitle from '../../ui/SubTitle/SubTitle';
import AreaAPI from '../../utils/AreaAPI';
import ContactPageAPI from '../../utils/ContactPageAPI';

const ContactPageSite = () => {

	const [data, setData] = useState([]);
	const [contact, setContact] = useState([]);
	const [area, setArea] = useState([]);

	const [loading, setLoading] = useState(false);

	// //console.log(contact);

	//Загрузить описание страницы контактов
	useEffect(() => {
		PageAPI.getPage('contact')
			.then(result => {
				// //console.log(result.data);
				if (result.status === 200) {
					setData(result.data[0])
					// setImgArray(result.data[0].picture)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	//Загрузить контакты
	useEffect(() => {
		loading === false &&
			ContactPageAPI.getContact()
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setContact(result.data[0])
						setLoading(true)
					}

					if (result.response?.status === 406) {
						//console.log(result.response.data)
						setData([])
						setLoading(true)
					}

					if (result.response?.status === 500) {
						//console.log(result.response.data)
					}
				})
	}, [loading]);

	const imgPath = "https://teatrcdr.renartdev.ru/teatr-api/img/area/"

	useEffect(() => {
		AreaAPI.getArea()
			.then(result => {
				if (result.status === 200) {
					// //console.log(result.data)
					setArea(result.data)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	// //console.log(data)
	return (
		<div>
			<div className={s.container}>
				<Routes>
					<Route index element={<HelmetUI title="Контакты - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
				</Routes>

				<div className={s.content}>
					{loading === true &&
						<div className={s.flex_container}>
							<div>
								<MainTitle>Контакты</MainTitle>
								<div className={s.contactList}>
									{/* {contact.map(item => {
										return <div key={item.empID} className={s.contactItem}>
											<SubTitle>{item?.position}</SubTitle>
											<p>{item?.fio}</p>
											<p>{item?.email}</p>
											<p className={s.description}>{item?.description}</p>

										</div>
									})} */}

									<div className={s.text} dangerouslySetInnerHTML={{ __html: contact.leftCol }}></div>
									<div className={s.text} dangerouslySetInnerHTML={{ __html: contact.rightCol }}></div>

								</div>

								<div className={s.areaList}>
									{area?.map(area => {
										return <div className={s.areaItem} key={area.areaID}>

											<div className={s.areaInformation}>


												{/* <div className={s.areaDoc}>
													{area.doc.length !== 0 && area.doc.map(doc => {
														return <a key={doc} href={imgPath + area?.areaID + '/doc/' + doc} target='_blank'>{doc}</a>
													})}
												</div> */}
												<div className={s.flexMapInfo}>
													<div >
														<SubTitle>{area.name}</SubTitle>
														<div dangerouslySetInnerHTML={{ __html: area.address }}></div>
													</div>
													<div dangerouslySetInnerHTML={{ __html: area.parkingInfo }}></div>
												</div>
												<div className={s.flexMap}>
													<div>
														<div className={s.areaDescription}>
															<SubTitle>{area.name}</SubTitle>
															<div dangerouslySetInnerHTML={{ __html: area.address }}></div>
														</div>
														<div className={s.map} dangerouslySetInnerHTML={{ __html: area.description }}></div>
													</div>
													<div>
														<div className={s.parkingDescription} dangerouslySetInnerHTML={{ __html: area.parkingInfo }}></div>
														<div className={s.map} dangerouslySetInnerHTML={{ __html: area.parking }}></div>
													</div>

												</div>

											</div>
										</div>
									})}
								</div>

								<div className={s.text} dangerouslySetInnerHTML={{ __html: contact.text }}></div>
							</div>
						</div>}

				</div>
			</div>
		</div >
	);
}

export default ContactPageSite;
