import React, { useState, useEffect } from 'react';
import s from './LabsListSite.module.scss'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import { format } from 'date-fns'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PerformanceAPI from '../../../utils/PerformanceAPI'
import LabsAPI from '../../../utils/LabsAPI';
import Fade from 'react-reveal/Fade';
import moment from 'moment';


const LabsListSite = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		loading === false &&
			LabsAPI.getLab()
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setData(result.data)
					}

					if (result.response?.status === 406) {
						//console.log(result.response.data)
						setData([])
					}

					if (result.response?.status === 500) {
						//console.log(result.response.data)
					}
				})


	}, [loading]);

	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState('');

	const handleOpen = (url) => {
		setUrl(url);
		setOpen(true);
	}
	const handleClose = () => {
		setUrl('');
		setOpen(false);
	}

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		height: '80%',
		bgcolor: 'background.paper',
		padding: '20px 5px'
	};

	const imgPath = "https://teatrcdr.renartdev.ru/teatr-api/img/performance/"
	// const [imgArray, setImgArray] = useState(data.picture.split('\n'));

	const sortData = data.sort((prev, next) => {
		// //console.log(a.events[0]?.date)
		// //console.log(b.events[0]?.date)

		// //console.log(new Date(prev.sort) + '-' + next.sort)
		return new Date(prev.sort) - new Date(next.sort)

	})

	//console.log(data)

	return (

		<div className={s.showList}>
			{data.map((lab) => {
				const str = lab.description.slice(0, 200) + '...';


				return (
					<Fade big key={lab.labID}>
						<Link to={`./${lab?.labID}`} >
							<div className={s.showTitle}>
								{lab.name}
							</div>
							<div className={s.place}>
								{lab.description}
							</div>
						</Link>
					</Fade>
				)
			})
			}


		</div >

	);
}

export default LabsListSite;
