import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.renartdev.ru/teatr-api';
	}

	//Запросить архивные записи
	get() {
		return axios.get(`${this._baseUrl}/archive`)
			.then(res => res)
			.catch(err => err)
	}
}

const ArchivehAPI = new Api();

export default ArchivehAPI;