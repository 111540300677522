import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import s from './ArtistyInformation.module.scss'
import EmployeesAPI from '../../../utils/EmployeesAPI';
import { useNavigate, useParams, Link } from "react-router-dom";
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import PerformanceAPI from '../../../utils/PerformanceAPI';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Scrollbar, Navigation, Pagination } from "swiper";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns'
import logo from './img/logo.jpg'
import cn from 'classnames'


import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ShowSlider from '../../../ui/ShowSlider/ShowSlider';

const ArtistyInformation = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [slideView, setSlideView] = useState(3);
	const { id } = useParams()

	const imgPath = "https://teatrcdr.renartdev.ru/teatr-api/img/employee/"
	const imgPathShow = "https://teatrcdr.renartdev.ru/teatr-api/img/performance/"

	const isMobile = useMediaQuery({ query: `(max-width: 425px)` });

	useEffect(() => {
		EmployeesAPI.getEmployeeForIdSite(id, 'artists')
			.then(result => {
				// //console.log(result.data[0]);
				if (result.status === 200) {
					setData(result.data[0])
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	useEffect(() => {
		if (isMobile) {
			setSlideView(1)
		} else {
			setSlideView(3)
		}
	}, [isMobile]);

	return (
		<>
			{loading === true &&
				<div className={cn(s.showInformation, data.picture.length === 0 && s.upMargin)} >
					<HelmetUI title={data.name} description={data.name} />
					{data.picture.length !== 0 &&
						<div className={s.carousel}>
							<Swiper
								spaceBetween={30}
								effect={"fade"}
								scrollbar={{
									hide: true,
								}}
								autoplay={{
									delay: 5000,
									disableOnInteraction: false,
								}}
								loop={true}
								modules={[EffectFade, Autoplay, Scrollbar, Navigation]}
								className={s.imageContainer}
								navigation
								style={{
									'--swiper-navigation-color': '#fff',
									'--swiper-pagination-color': '#fff'
								}}
							>
								{data?.picture?.map(image => {
									return <SwiperSlide key={image}><img className={s.carouselImg} key={image} src={imgPath + id + '/' + image} alt={image} /></SwiperSlide>
								})}
							</Swiper>
						</div>
					}
					<HelmetUI title={data?.fio + " - Центр драматургии и режиссуры"} description="Театр Центр драматургии и режиссуры" />

					<Grid container>

						<Grid xs={12} sm={12} sx={{ pr: '15px' }}>
							<MainTitle>{data.fio.toUpperCase()}</MainTitle>

							{data.description !== "" &&
								<div className={s.description}>
									<div className={s.additionallyText}>
										{data.description}
									</div>
								</div>
							}
						</Grid>

						{data?.performanceList?.length > 0 && <Grid xs={12} sm={12} sx={{ pb: '15px', mt: '15px', pt: '15px' }}>
							<ShowSlider data={data} />
						</Grid>
						}
					</Grid>


				</div >
			}
		</>
	);
}

export default ArtistyInformation;
