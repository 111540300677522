import React, { useState, useEffect } from 'react';
import s from './TeamListSite.module.scss';
import { Link } from "react-router-dom";
import EmployeesAPI from './../../../utils/EmployeesAPI';
import Grid from '@mui/material/Unstable_Grid2';
import SubTitle from '../../../ui/SubTitle/SubTitle';
import Fade from 'react-reveal/Fade';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { CircularProgress } from '@mui/material';


const TeamListSite = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		loading === false &&
			EmployeesAPI.getEmployeeForSite('team')
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setData(result.data)
						setLoading(true)
					}

					if (result.response?.status === 406) {
						//console.log(result.response.data)
						setData([])
						setLoading(true)
					}

					if (result.response?.status === 500) {
						//console.log(result.response.data)
					}
				})
	}, [loading]);

	const director = data?.filter(item => {
		return item?.profession?.toLowerCase().includes("режиссер")
	})

	const author = data?.filter(item => {
		return item?.profession?.toLowerCase().includes("автор")
	})

	const videoArtist = data?.filter(item => {
		return item?.profession?.toLowerCase().includes("видеохудожник")
	})

	const composer = data?.filter(item => {
		return item?.profession?.toLowerCase().includes("композитор")
	})

	const photographer = data?.filter(item => {
		return item?.profession?.toLowerCase().includes("фотограф")
	})

	const choreographer = data?.filter(item => {
		return item?.profession?.toLowerCase().includes("хореограф")
	})

	const artist = data?.filter(item => {
		return item?.profession?.toLowerCase().includes("художник")
	})

	const lightArtist = data?.filter(item => {
		return item?.profession?.toLowerCase().includes("художник по свету")
	})

	return (
		<>
			{loading === true ?
				<div>
					<Fade cascade>
						<Grid container>
							<Grid xs={12} sx={{ pb: '15px', mt: '35px' }}>
								<div className={s.title}>Режиссеры</div>
								<Grid sx={{ mt: '15px' }} container>
									{director?.map(user => {
										return (
											<Grid xs={12} sm={6} lg={4} sx={{ pb: '15px' }}>
												<Fade>
													<div className={s.teamItem}>
														{user.description === "" ? <>{user.fio}</> : <Link className={s.link} to={`./${user?.empID}`} >{user.fio}</Link>}
													</div>
												</Fade>
											</Grid>
										)
									})}
								</Grid>
							</Grid>
							<Grid xs={12} sx={{ pb: '15px', mt: '35px' }}>
								<div className={s.title}>Хореографы</div>
								<Grid sx={{ mt: '15px' }} container>
									{choreographer?.map(user => {
										return (
											<Grid xs={12} sm={6} lg={4} sx={{ pb: '15px' }}>
												<Fade>
													<div className={s.teamItem}>{user.description === "" ? <>{user.fio}</> : <Link className={s.link} to={`./${user?.empID}`} >{user.fio}</Link>}</div>
												</Fade>
											</Grid>
										)
									})}
								</Grid>
							</Grid>
							<Grid xs={12} sx={{ pb: '15px', mt: '35px' }}>
								<div className={s.title}>Композиторы</div>
								<Grid sx={{ mt: '15px' }} container>
									{composer?.map(user => {
										return (
											<Grid xs={12} sm={6} lg={4} sx={{ pb: '15px' }}>
												<Fade>
													<div className={s.teamItem}>{user.description === "" ? <>{user.fio}</> : <Link className={s.link} to={`./${user?.empID}`} >{user.fio}</Link>}</div>
												</Fade>
											</Grid>
										)
									})}
								</Grid>
							</Grid>
							<Grid xs={12} sx={{ pb: '15px', mt: '35px' }}>
								<div className={s.title}>Художники</div>
								<Grid sx={{ mt: '15px' }} container>
									{artist?.map(user => {
										return (
											<Grid xs={12} sm={6} lg={4} sx={{ pb: '15px' }}>
												<Fade>
													<div className={s.teamItem}>{user.description === "" ? <>{user.fio}</> : <Link className={s.link} to={`./${user?.empID}`} >{user.fio}</Link>}</div>
												</Fade>
											</Grid>
										)
									})}
								</Grid>
							</Grid>
							<Grid xs={12} sx={{ pb: '15px', mt: '35px' }}>
								<div className={s.title}>Видеохудожники</div>
								<Grid sx={{ mt: '15px' }} container>
									{videoArtist?.map(user => {
										return (
											<Grid xs={12} sm={6} lg={4} sx={{ pb: '15px' }}>
												<Fade>
													<div className={s.teamItem}>{user.description === "" ? <>{user.fio}</> : <Link className={s.link} to={`./${user?.empID}`} >{user.fio}</Link>}</div>
												</Fade>
											</Grid>
										)
									})}
								</Grid>
							</Grid>
							<Grid xs={12} sx={{ pb: '15px', mt: '35px' }}>
								<div className={s.title}>Художники по свету</div>
								<Grid sx={{ mt: '15px' }} container>
									{lightArtist?.map(user => {
										return (
											<Grid xs={12} sm={6} lg={4} sx={{ pb: '15px' }}>
												<Fade>
													<div className={s.teamItem}>{user.description === "" ? <>{user.fio}</> : <Link className={s.link} to={`./${user?.empID}`} >{user.fio}</Link>}</div>
												</Fade>
											</Grid>
										)
									})}
								</Grid>
							</Grid>
							<Grid xs={12} sx={{ pb: '15px', mt: '35px' }}>
								<div className={s.title}>Авторы</div>
								<Grid sx={{ mt: '15px' }} container>
									{author?.map(user => {
										return (
											<Grid xs={12} sm={6} lg={4} sx={{ pb: '15px' }}>
												<Fade>
													<div className={s.teamItem}>{user.description === "" ? <>{user.fio}</> : <Link className={s.link} to={`./${user?.empID}`} >{user.fio}</Link>}</div>
												</Fade>
											</Grid>
										)
									})}
								</Grid>
							</Grid>
							{/* <Grid xs={12} sx={{ pb: '15px', mt: '35px' }}>
						<div className={s.title}>Фотографы</div>
						<Grid sx={{ mt: '15px' }} container>
							{photographer?.map(user => {
								return (
									<Grid xs={12} sm={6} lg={4} sx={{ pb: '15px' }}>
										<Fade>
											<div className={s.teamItem}>{user.description === "" ? <>{user.fio}</> : <Link className={s.link} to={`./${user?.empID}`} >{user.fio}</Link>}</div>
										</Fade>
									</Grid>
								)
							})}
						</Grid>
					</Grid> */}
						</Grid>
					</Fade>


				</div> : <CircularProgress/>
			}
		</>


	);
}

export default TeamListSite;
