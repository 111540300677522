import "./App.scss";
// import "reset-css";
import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  ScrollRestoration,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Layout from "./components/Layout/Layout";
import LayoutAdmin from "./components/LayoutAdmin/LayoutAdmin";
import AdminPage from "./pages/Admin/AdminPage/AdminPage";
import HomePage from "./pages/HomePage/HomePage";
import Access from "./pages/Admin/Access/Access";
import MainTitle from "./ui/MainTitle/MainTitle";
import { HelmetProvider } from "react-helmet-async";
import UserListPage from "./pages/Admin/UserListPage/UserListPage";
import UserAddPage from "./pages/Admin/UserAddPage/UserAddPage";
import UserEditPage from "./pages/Admin/UserEditPage/UserEditPage";
import Employees from "./pages/Admin/Employees/Employees";
import ShowPage from "./pages/Admin/ShowPage/ShowPage";
import AfishaPage from "./pages/Admin/AfishaPage/AfishaPage";
import ShowPageSite from "./pages/ShowPageSite/ShowPageSite";
import TeamPageSIte from "./pages/TeamPageSite/TeamPageSIte";
import ArtistyPageSite from "./pages/ArtistyPageSite/ArtistyPageSite";
import AfishaPageSite from "./pages/AfishaPageSite/AfishaPageSite";
import NewsPage from "./pages/Admin/NewsPage/NewsPage";
import NewsPageSite from "./pages/NewsPageSite/NewsPageSite";
import HistoryPage from "./pages/Admin/HistoryPage/HistoryPage";
import HistoryPageSite from "./pages/HistoryPageSite/HistoryPageSite";
import VaсanciesPage from "./pages/Admin/VaсanciesPage/VaсanciesPage";
import VacnciesPageSite from "./pages/VacnciesPageSite/VacnciesPageSite";
import SoundramaPageSite from "./pages/SoundramaPageSite/SoundramaPageSite";
import LabsPage from "./pages/Admin/LabsPage/LabsPage";
import LabsPageSite from "./pages/LabsPageSite/LabsPageSite";
import ProjectPage from "./pages/Admin/ProjectPage/ProjectPage";
import ProjectPageSite from "./pages/ProjectPageSite/ProjectPageSite";
import ScrollToTop from "./utils/ScrollToTop";
import TicketPage from "./pages/Admin/TicketPage/TicketPage";
import TicketPageSite from "./pages/TicketPageSite/TicketPageSite";
import InformationPage from "./pages/Admin/InformationPage/InformationPage";
import InformationPageSite from "./pages/InformationPageSite/InformationPageSite";
import PartnersPage from "./pages/Admin/PartnersPage/PartnersPage";
import PartnersPageSite from "./pages/PartnersPageSite/PartnersPageSite";
import AreaPage from "./pages/Admin/AreaPage/AreaPage";
import AreaPageSite from "./pages/AreaPageSite/AreaPageSite";
import ContactPage from "./pages/Admin/ContactPage/ContactPage";
import ContactPageSite from "./pages/ContactPageSite/ContactPageSite";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./hook/AuthProvider";
import LoginPage from "./pages/LoginPage/LoginPage";
import AdminRedirect from "./components/Admin/AdminRedirect";
import CafePage from "./pages/Admin/CafePage/CafePage";
import CafePageSite from "./pages/CafePageSite/CafePageSite";
import PushkinPageSite from "./pages/PushkinPageSite/PushkinPageSite";
import SpecialVersion from 'special-version-module';
import SearchPageSite from "./pages/SearchPageSite/SearchPageSite";
import ArchivePageSite from "./pages/ArchivePageSite/ArchivePageSite";

import * as utils from "./utils/utils.js";


const theme = createTheme({
  palette: {
    primary: {
      main: "#37246a",
    },
    // text: {
    //   primary: '#fff'
    // },
    // label: {
    //   main: '#86C232',
    //   secondary: '#fff'
    // },
    // error: {
    //   info: '#86C232',
    //   main: '#fff'
    // }
  },
});

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading === false) {
      const version = new SpecialVersion('.js-version')
      setLoading(true)
    }
  }, []);



  return (

    <>

      <AuthProvider>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ScrollToTop />
              <Routes>

                <Route path="/login" element={<LoginPage />} />

                <Route path="/" element={<Layout />}>
                  <Route index element={<HomePage />} />
                  <Route path="afisha" element={<AfishaPageSite />} />
                  <Route path="spektakli/*" element={<ShowPageSite />} />
                  <Route path="sobytiya/*" element={<NewsPageSite />} />
                  <Route
                    path="pushkin_card"
                    element={<PushkinPageSite />}
                  />

                  <Route
                    path="o-teatre/istoriya"
                    element={<HistoryPageSite />}
                  />
                  <Route
                    path="o-teatre/soundrama"
                    element={<SoundramaPageSite />}
                  />
                  <Route
                    path="o-teatre/laboratorii/*"
                    element={<LabsPageSite />}
                  />
                  <Route
                    path="o-teatre/proekty/*"
                    element={<ProjectPageSite />}
                  />
                  <Route
                    path="o-teatre/artisty/*"
                    element={<ArtistyPageSite />}
                  />
                  <Route path="o-teatre/komanda/*" element={<TeamPageSIte />} />
                  <Route
                    path="o-teatre/ploshhadki"
                    element={<AreaPageSite />}
                  />
                  <Route
                    path="o-teatre/oficialnaya-informaciya"
                    element={<InformationPageSite />}
                  />
                  <Route
                    path="o-teatre/vakansii"
                    element={<VacnciesPageSite />}
                  />
                  <Route
                    path="o-teatre/partnery"
                    element={<PartnersPageSite />}
                  />
                  <Route path="o-teatre/cdr-cafe" element={<CafePageSite />} />
                  <Route path="o-teatre/archive" element={<ArchivePageSite />} />

                  <Route path="kupit-bilety" element={<TicketPageSite />} />
                  <Route path="kontakty" element={<ContactPageSite />} />
                  <Route path="search" element={<SearchPageSite />} />

                  <Route path="*" element={<div><h1>404 Error</h1><h1>Page Not Found</h1> <Navigate to="/"/></div>} />
                </Route>

                <Route
                  path="/admin"
                  element={
                    <ProtectedRoute>
                      <LayoutAdmin />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<AdminRedirect />} />
                  <Route path="afisha/*" element={<AfishaPage />} />
                  <Route path="spektakli/*" element={<ShowPage />} />
                  <Route path="sobytiya/*" element={<NewsPage />} />
                  <Route path="employees/*" element={<Employees />} />
                  <Route path="access" element={<Access />}>
                    <Route index element={<UserListPage />} />
                    <Route path=":id" element={<UserEditPage />} />
                    <Route path="add" element={<UserAddPage />} />
                  </Route>
                  <Route path="istoriya/*" element={<HistoryPage />} />
                  <Route path="laboratorii/*" element={<LabsPage />} />
                  <Route path="proekty/*" element={<ProjectPage />} />
                  <Route path="ploshhadki/*" element={<AreaPage />} />
                  <Route
                    path="oficialnaya-informaciya/*"
                    element={<InformationPage />}
                  />
                  <Route path="vakansii/*" element={<VaсanciesPage />} />
                  <Route path="partnery/*" element={<PartnersPage />} />
                  <Route path="cdr-cafe/*" element={<CafePage />} />
                  <Route path="kupit-bilety/*" element={<TicketPage />} />
                  <Route path="kontakty/*" element={<ContactPage />} />
                  <Route
                    path="*"
                    element={
                      <MainTitle>Упс... такой страницы больше нет</MainTitle>
                    }
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </HelmetProvider>
      </AuthProvider>
    </>
  );
}

export default App;
