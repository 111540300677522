import React from 'react';
import s from './Departament.module.scss'
import depart from './img/depart.png'
import { Link } from 'react-router-dom'

const Departament = () => {
	return (
		<div className={s.depart}>
			<Link to='https://www.mos.ru/kultura/' target="_blank">
				<img src={depart} alt="Департамент культуры города Москвы" />
			</Link>
		</div>
	);
}

export default Departament;
