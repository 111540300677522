import React, {useState, useEffect}from 'react';
import s from "./AreaPage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import Button from '@mui/material/Button';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import ShowList from '../../../components/Admin/Show/ShowList/ShowList';
import ShowForm from '../../../components/Admin/Show/ShowForm/ShowForm';
import LabList from '../../../components/Admin/Labs/LabList/LabList';
import LabForm from '../../../components/Admin/Labs/LabForm/LabForm';
import AreaList from '../../../components/Admin/Area/AreaList/AreaList';
import AreaForm from '../../../components/Admin/Area/AreaForm/AreaForm';

const AreaPage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="Площадки" description="Управление лабораториями театра" robots="none" />} />
				<Route path="add" element={<HelmetUI title="Добавление площадки" description="Добавление новой площадки" robots="none" />} />
				<Route path=":id" element={<HelmetUI title="Редактирование площадки" description="Редактирование площадки" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Площадки</MainTitle>} />
				<Route path=":id" element={<MainTitle back>Редактировать</MainTitle>} />
				<Route path="add" element={<MainTitle back>Добавление площадки</MainTitle>} />
			</Routes>

			<Routes>
				<Route index element={
					<div className={s.add}>
						<Link to='./add'>
							<Button sx={{ borderRadius: '0px' }} variant="outlined">Добавить</Button>
						</Link>
					</div>
				} />
			</Routes>


			<div className={s.content}>
				<Routes>
					<Route index element={<AreaList />} />
					<Route path="add" element={<AreaForm />} />
					<Route path=":id" element={<AreaForm g1={true} />} />
				</Routes>
			</div>
		</>
	);
}

export default AreaPage;
