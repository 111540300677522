import React, { useEffect, useState } from 'react';
import s from "./ContactForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PageAPI from '../../../../utils/PageAPI';
import TextEditor from '../../../../ui/TextEditor/TextEditor';
import SubTitle from '../../../../ui/SubTitle/SubTitle';
import ContactPageAPI from '../../../../utils/ContactPageAPI';


const inflection = require('inflection')

const ContactForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [redactor, setRedactor] = useState();
	const [left, setLeft] = useState();
	const [right, setRight] = useState();


	const { control, handleSubmit, formState: { errors, isDirty, isValid }, setValue, getValues } = useForm(
		{
			mode: "onSubmit"
		}
	);

	// //console.log(redactor)

	useEffect(() => {
		ContactPageAPI.getContact()
			.then(result => {
				console.log(result.data);
				if (result.status === 200) {
					setData(result.data[0])
					setRedactor(result.data[0].text)
					setLeft(result.data[0].leftCol)
					setRight(result.data[0].rightCol)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	useEffect(() => {
		// setValue('newsID', nanoid());
		// setValue('picture[]', file);
		setValue('name', 'contact');
	}, [setValue, data, loading]);

	//Изменить событие
	const update = (form) => {
		form.text = redactor;
		form.leftCol = left;
		form.rightCol = right;

		//console.log(form)
		ContactPageAPI.updateContact(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					// navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}



	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={handleSubmit(update)}>
					<SubTitle>Художественная часть:</SubTitle>
					<TextEditor redactor={left} setRedactor={setLeft} placeholder='Начните вводить содержимое страницы...' />
					<br />
					<SubTitle>Административная часть:</SubTitle>
					<TextEditor redactor={right} setRedactor={setRight} placeholder='Начните вводить содержимое страницы...' />
					<br />
					<SubTitle>Информация:</SubTitle>
					<TextEditor redactor={redactor} setRedactor={setRedactor} placeholder='Начните вводить содержимое страницы...' />



					{/* <div className={s.editor}>
						<br></br>
						<ReactQuill theme="snow" modules={toolbarOptions} value={redactor} onChange={setRedactor} />
					</div> */}
					<br/>

					<div className={s.button}>
						<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
							<>Назад</>
						</Button>

						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Создать</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default ContactForm;
