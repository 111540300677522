import React, { useState, useEffect } from 'react';
import s from './ProjectListSite.module.scss'
import { Link } from "react-router-dom";
import LabsAPI from '../../../utils/LabsAPI';
import ProjectAPI from '../../../utils/ProjectAPI';
import Fade from 'react-reveal/Fade';



const ProjectListSite = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		loading === false &&
			ProjectAPI.getProject()
				.then(result => {
					// //console.log(result);
					if (result.status === 200) {
						setData(result.data)
					}

					if (result.response?.status === 406) {
						//console.log(result.response.data)
						setData([])
					}

					if (result.response?.status === 500) {
						//console.log(result.response.data)
					}
				})


	}, [loading]);

	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState('');

	const handleOpen = (url) => {
		setUrl(url);
		setOpen(true);
	}
	const handleClose = () => {
		setUrl('');
		setOpen(false);
	}

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		height: '80%',
		bgcolor: 'background.paper',
		padding: '20px 5px'
	};

	const imgPath = "https://teatrcdr.renartdev.ru/teatr-api/img/performance/"
	// const [imgArray, setImgArray] = useState(data.picture.split('\n'));

	const sortData = data.sort((prev, next) => {
		// //console.log(a.events[0]?.date)
		// //console.log(b.events[0]?.date)

		// //console.log(new Date(prev.sort) + '-' + next.sort)
		return new Date(prev.sort) - new Date(next.sort)

	})

	//console.log(data)

	return (

		<div className={s.showList}>
			{data.map((lab) => {
				const str = lab.description.slice(0, 200) + '...';


				return (
					<Fade big key={lab.projectID}>
						<Link to={`./${lab?.projectID}`} preventScrollReset={true}>
							<div className={s.showTitle}>
								{lab.name}
							</div>
							<div className={s.place}>
								{lab.description}
							</div>
						</Link>
					</Fade>
				)
			})
			}
			<Fade big key="project-cafe">
				<Link to="/o-teatre/cdr-cafe" preventScrollReset={true}>
					<div className={s.showTitle}>
						ЦДР кафе
					</div>
					<div className={s.place}>
						«ЦДР-Кафе» в фойе Центра драматургии и режиссуры на Соколе – не просто театральный буфет, но постоянно действующее кафе, всегда открытое для посетителей. Здесь вас ждут аппетитные завтраки, кофе с собой, сытные обеды, горячая выпечка, салаты и сэндвичи, десерты и мороженое.
					</div>
				</Link>
			</Fade>

		</div >

	);
}

export default ProjectListSite;
