import { createContext, useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState('');
	// const navigate = useNavigate();

	const login = async (data) => {
		// //console.log(localStorage.getItem('user'))
		// localStorage.getItem('user') !== null && setUser(localStorage.getItem('user'))
		setUser(data);
		// navigate("/admin");
	};

	const logout = () => {
		setUser(null);
		// navigate("/login", { replace: true });
	};

	const value = useMemo(
		() => ({
			user,
			login,
			logout
		}),
		[user]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
	return useContext(AuthContext);
};
