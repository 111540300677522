import React, { useState, useEffect } from 'react';
import s from './ShowInformation.module.scss'
import { useNavigate, useParams, Link } from "react-router-dom";
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import PerformanceAPI from '../../../utils/PerformanceAPI';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Scrollbar, Navigation } from "swiper";
import Grid from '@mui/material/Unstable_Grid2';
import pushkin from './img/pushkin.jpg'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import { format } from 'date-fns'
import moment from 'moment';


import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import ShowListSite from '../ShowListSite/ShowListSite';
import SubTitle from './../../../ui/SubTitle/SubTitle';


const ShowInformation = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [url, setUrl] = useState('');

	const handleOpen = (url) => {
		setUrl(url);
		// setOpen(true);
		window.TLIframe.showPopup({url: url})
	}
	const handleClose = () => {
		setUrl('');
		setOpen(false);
	}
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		height: '80%',
		bgcolor: 'background.paper',
		padding: '20px 5px'
	};

	const { id } = useParams()
	const navigate = useNavigate();

	const imgPath = "https://teatrcdr.renartdev.ru/teatr-api/img/performance/"

	useEffect(() => {
		PerformanceAPI.getPerformanceForIdSite(id)
			.then(result => {
				// //console.log(result.data[0]);
				if (result.status === 200) {
					setData(result.data[0])
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, [id]);

	const params = {
		spaceBetween: 30,
		effect: 'fade',
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
	}

	//console.log(data)

	return (
		<>
			{loading === true &&
				<div className={s.showInformation} >
					<HelmetUI title={data.name} description={data.name} />
					<div className={s.carousel}>
						<Swiper
							spaceBetween={30}
							effect={"fade"}
							autoplay={{
								delay: 5000,
								disableOnInteraction: false,
							}}
							loop={true}
							modules={[EffectFade, Autoplay, Scrollbar, Navigation]}
							navigation
							className={s.imageContainer}
							style={{
								'--swiper-navigation-color': '#fff',
								'--swiper-pagination-color': '#fff',
							}}
						>
							{data?.picture?.map(image => {
								return <SwiperSlide key={image}>
									<div className={s.carouselItem}>
										<img className={s.carouselImg} key={image} src={imgPath + id + '/' + image} alt={image} />
									</div>
								</SwiperSlide>
							})}
						</Swiper>
					</div>

					<Grid container>
						<Grid xs={12} sm={12} lg={3} sx={{ pb: '15px' }} order={{ xs: 1, sm: 1, lg: 2 }}>
							{data.events.length !== 0 &&
								<div className={s.events}>
									<p className={s.saleTitle}>Купить билеты:</p>
									{data.events.map(event => {
										return <div key={event.date}>
											<div className={s.eventStyle} onClick={() => handleOpen(event.url)}>
											{moment(event.date).format('DD.MM.yyyy')} <br />
												<span className={s.time}>{moment(event.date).format('HH:mm')}</span>
												{event.transfer !== '0000-00-00 00:00:00' && <div className={s.transfer}>
													{moment(event.transfer).format('Перенос с DD.MM.YYYY')}
												</div>}
											</div>

											<Modal
												open={open}
												onClose={handleClose}
												aria-labelledby="modal-modal-title"
												aria-describedby="modal-modal-description"
											>

												<Box sx={style}>
													<IconButton
														aria-label="close"
														onClick={handleClose}
														sx={{
															position: 'absolute',
															right: -12,
															top: -40,
															color: (theme) => theme.palette.grey[500],
														}}
													>
														<CloseIcon />
													</IconButton>
													<iframe src={url} height='100%' width='100%' />
												</Box>
											</Modal>
										</div>
									})}

								</div>

							}
							{data.pushkin === "1" &&
								<div className={s.paramBlockPuskin}>
									<img className={s.pushkinImg} src={pushkin} alt="Пушкинская карта" />
								</div>
							}
						</Grid>
						<Grid xs={12} sm={12} lg={9} sx={{ pr: '15px' }} order={{ xs: 2, sm: 2, lg: 1 }}>
							<MainTitle>{data.name.toUpperCase()}</MainTitle>

							<div className={s.age}>
								<span className={s.ageTitle}>{data.age}</span>
							</div>



							<div className={s.paramBlock}>
								<span className={s.paramTitle}>Площадка:</span> <span className={s.name}>{data.area}</span>
							</div>

							{/* <div className={s.paramBlock}>
								<div className={s.paramTitle}>{data.director.length > 1 ? <>Режиссеры: </> : <>Режиссёр: </>}</div> <div className={s.name}>
									{data?.director?.map(item => {
										return <Link key={item?.empID} to={`/o-teatre/komanda/${item?.empID}`}><div>{item.fio}</div></Link>
									})}
								</div>
							</div> */}

							{/* {data.author.length > 0 &&
								<div className={s.paramBlock}>
									<div className={s.paramTitle}>{data.author.length > 1 ? <>Авторы: </> : <>Автор: </>}</div> <div className={s.name}>
										{data?.author?.map(item => {
											return <Link key={item?.empID} to={`/o-teatre/komanda/${item?.empID}`}><div>{item.fio}</div></Link>
										})}
									</div>
								</div>
							}

							{data.artist.length > 0 &&
								<div className={s.paramBlock}>
									<div className={s.paramTitle}>{data.artist.length > 1 ? <>Художники-постановщики: </> : <>Художник-постановщик: </>}</div> <div className={s.name}>
										{data?.artist?.map(item => {
											return <Link key={item?.empID} to={`/o-teatre/komanda/${item?.empID}`}><div>{item.fio}</div></Link>
										})}
									</div>
								</div>
							}

							{data.choreographer.length > 0 &&
								<div className={s.paramBlock}>
									<div className={s.paramTitle}>{data.choreographer.length > 1 ? <>Хореографы: </> : <>Хореограф: </>}</div> <div className={s.name}>
										{data?.choreographer?.map(item => {
											return <Link key={item?.empID} to={`/o-teatre/komanda/${item?.empID}`}><div>{item.fio}</div></Link>
										})}
									</div>
								</div>
							}

							{data.composer.length > 0 &&
								<div className={s.paramBlock}>
									<div className={s.paramTitle}>{data.composer.length > 1 ? <>Композиторы: </> : <>Композитор: </>}</div> <div className={s.name}>
										{data?.composer?.map(item => {
											return <Link key={item?.empID} to={`/o-teatre/komanda/${item?.empID}`}><div>{item.fio}</div></Link>
										})}
									</div>
								</div>
							}

							{data.photographer.length > 0 &&
								<div className={s.paramBlock}>
									<div className={s.paramTitle}>{data.photographer.length > 1 ? <>Фотографы: </> : <>Фотограф: </>}</div> <div className={s.name}>
										{data?.photographer?.map(item => {
											return <Link key={item?.empID} to={`/o-teatre/komanda/${item?.empID}`}><div>{item.fio}</div></Link>
										})}
									</div>
								</div>
							}

							{data.lightingArtist.length > 0 &&
								<div className={s.paramBlock}>
									<div className={s.paramTitle}>{data.lightingArtist.length > 1 ? <>Художники по свету: </> : <>Художник по свету: </>}</div> <div className={s.name}>
										{data?.lightingArtist?.map(item => {
											return <Link key={item?.empID} to={`/o-teatre/komanda/${item?.empID}`}><div>{item.fio}</div></Link>
										})}
									</div>
								</div>
							}

							{data.soundDesigner.length > 0 &&
								<div className={s.paramBlock}>
									<div className={s.paramTitle}>{data.soundDesigner.length > 1 ? <>Звукорежессеры: </> : <>Звукорежессер: </>}</div> <div className={s.name}>
										{data?.soundDesigner?.map(item => {
											return <Link key={item?.empID} to={`/o-teatre/komanda/${item?.empID}`}><div>{item.fio}</div></Link>
										})}
									</div>
								</div>
							}

							{data.videoArtist.length > 0 &&
								<div className={s.paramBlock}>
									<div className={s.paramTitle}>{data.videoArtist.length > 1 ? <>Видеохудожники: </> : <>Видеохудожник: </>}</div> <div className={s.name}>
										{data?.videoArtist?.map(item => {
											return <Link key={item?.empID} to={`/o-teatre/komanda/${item?.empID}`}><div>{item.fio}</div></Link>
										})}
									</div>
								</div>
							}

							{data.actor.length > 0 &&
								<div className={s.paramBlock}>
									<div className={s.paramTitle}>{data.actor.length > 1 ? <>Исполнители: </> : <>Исполнитель: </>}</div> <div className={s.name}>
										{data?.actor?.map(item => {
											return <Link key={item?.empID} to={`/o-teatre/artisty/${item?.empID}`}><div>{item.fio}</div></Link>
										})}
									</div>
								</div>
							} */}

							{data.additionally !== "" &&
								<div className={s.additionally}>
									<div className={s.additionallyText} dangerouslySetInnerHTML={{ __html: data.additionally }}>

									</div>
								</div>
							}

							{/* {data.duration.length !== "" &&
								<div className={s.paramBlock}>
									<div className={s.paramTitle}>Продолжительность: </div> <div className={s.name}>
										{data.duration}
									</div>
								</div>
							} */}

							{/* {data.description !== "" &&
								<div className={s.description}>
									<div className={s.descriptionText}>
										{data.description}
									</div>
								</div>
							} */}
						</Grid>

					</Grid>


					<br />
					<br />
					<br />
					<br />
					<div className={s.centerBlock}><SubTitle>Ближайшие спектакли</SubTitle></div>
					<br />
					<br />
					<ShowListSite id={id} />

				</div>


			}

		</>
	);
}


export default ShowInformation;
