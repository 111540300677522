import React from 'react';
import s from './LabListItem.module.scss'
const inflection = require('inflection')

const LabListItem = ({ data }) => {


	return (
		<div className={s.line} >
			<div className={s.fio}>{inflection.titleize(data?.name)}</div>
			<div className={s.login}>
				Лаборатория
			</div>
		</div>
	);
}

export default LabListItem;
