import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.renartdev.ru/teatr-api';
	}

	//Получить список пользователей
	getAllUsers() {
		return axios.get(`${this._baseUrl}/users`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить пользователя
	getUser(id) {
		return axios.get(`${this._baseUrl}/users/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Авторизация
	login(login, password) {
		return axios.get(`${this._baseUrl}/login`, {
			params: {
				login: login,
				password: password
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Добавить пользователя
	addUser(data) {
		return axios.post(`${this._baseUrl}/users`, data)
			.then(res => res)
			.catch(err => err)
	}

	//Изменить пользователя
	updateUser(data, id) {
		return axios.patch(`${this._baseUrl}/users/${id}`, data)
			.then(res => res)
			.catch(err => err)
	}

	//Удалить пользователя
	deleteUser(id) {
		return axios.delete(`${this._baseUrl}/users/${id}`)
			.then(res => res)
			.catch(err => err)
	}


}

const UserAPI = new Api();

export default UserAPI;