import React from 'react';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { Helmet } from 'react-helmet-async';

const AdminPage = () => {
	return (
		<>
			<Helmet>
				<title>Панель администратора</title>
				<meta name="description" content="Панель администратора" />
				<meta name="robots" content="none" />
			</Helmet>
			<MainTitle>
				Главная страница панели администрирования
			</MainTitle>
		</>
	);
}

export default AdminPage;
