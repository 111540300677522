import React, { useEffect, useState } from 'react';
import s from "./CafeForm.module.scss";
import { useForm} from "react-hook-form";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CafeAPI from '../../../../utils/CafeAPI';
import InputFileUI from '../../../../ui/InputFileUI/InputFileUI';
import axios from 'axios'
import 'react-quill/dist/quill.snow.css';
import SubTitle from '../../../../ui/SubTitle/SubTitle';
import TextEditor from '../../../../ui/TextEditor/TextEditor';
import InputDocFileUI from '../../../../ui/InputDocFileUI/InputDocFileUI';

const CafeForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [menu, setMenu] = useState([]);
	const [bar, setBar] = useState([]);
	const [mainImage, setMainImage] = useState([]);
	const [menuImage, setMenuImage] = useState([]);
	const [barImage, setBarImage] = useState([]);
	const [description, setDescription] = useState();
	const [workSchedule, setWorkSchedule] = useState();


	const {handleSubmit, setValue} = useForm(
		{
			mode: "onSubmit"
		}
	);

	// //console.log(redactor)
	useEffect(() => {
		CafeAPI.getCafe()
			.then(result => {
				//console.log(result.data[0]);
				if (result.status === 200) {
					setData(result.data[0])
					setDescription(result.data[0].description)
					setWorkSchedule(result.data[0].workSchedule)
					// setRedactor(result.data[0].text)
					// setImgArray(result.data[0].picture)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	useEffect(() => {
		// setValue('newsID', nanoid());
		setValue('picture[]', mainImage);
		setValue('menuImage[]', menuImage);
		setValue('barImage[]', barImage);
		setValue('bar[]', bar);
		setValue('menu[]', menu);
	}, [mainImage, menuImage, barImage, bar, menu, setValue]);

	useEffect(() => {
		if (loading === true && g1) {
			dwnMainImage()
			dwnBarImage()
			dwnMenuImage()
			data?.menu !== "" && dwnMenu()
			data?.bar !== "" && dwnBar()
		}
		// setloadingFiles(true)
	}, [loading]);

	//Добавить событие
	const add = (form) => {
		// form.description = description;
		// form.workSchedule = workSchedule;
		// form.showID = form.showID.join()
		// form.publish = form.publish === true ? 1 : 0;
		// form.mainPage = form.mainPage === true ? 1 : 0;

		// //console.log(form)

		// NewsAPI.addNews(form)
		// 	.then(result => {
		// 		// //console.log(result);
		// 		if (result.status === 200) {
		// 			navigate(-1)
		// 		}

		// 		if (result.response?.status === 500) {
		// 			// //console.log(result.response.data)
		// 		}
		// 	})
	}

	//Изменить событие
	const update = (form) => {
		form.description = description;
		form.workSchedule = workSchedule;


		console.log(form)
		CafeAPI.updateCafe(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					// navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Загрузка главное фото
	const dwnMainImage = () => {
		const dt = new DataTransfer();
		console.log(data.picture)

		const downloadImg =
			axios.get(`https://teatrcdr.renartdev.ru/teatr-api/img/cafe/img/${data?.picture}`, {
				responseType: 'blob'
			})
				.then(res => {
					// console.log(res)
					// res.headers['content-type'].includes('image') &&
					dt.items.add(new File([res.data], `${data?.picture}`, { type: res.headers['content-type'] }));
				})
				.then(() => {
					var file_list = dt.files;
					// console.log(file_list);
					setMainImage(file_list);
					// dt.clearData();
				})
				.catch(err => err)

	}

	//Загрузка фото для бара
	const dwnBarImage = () => {
		const dt = new DataTransfer();
		console.log(data.picture)

		const downloadBarImg =
			axios.get(`https://teatrcdr.renartdev.ru/teatr-api/img/cafe/barImage/${data?.barImage}`, {
				responseType: 'blob'
			})
				.then(res => {
					// console.log(res)
					// res.headers['content-type'].includes('image') &&
					dt.items.add(new File([res.data], `${data?.barImage}`, { type: res.headers['content-type'] }));
				})
				.then(() => {
					var file_list = dt.files;
					// console.log(file_list);
					setBarImage(file_list);
				})
				.catch(err => err)

	}

	//Загрузка фото для меню
	const dwnMenuImage = () => {
		const dt = new DataTransfer();
		// console.log(data.picture)

		const downloadBarImg =
			axios.get(`https://teatrcdr.renartdev.ru/teatr-api/img/cafe/menuImage/${data?.menuImage}`, {
				responseType: 'blob'
			})
				.then(res => {
					// console.log(res)
					// res.headers['content-type'].includes('image') &&
					dt.items.add(new File([res.data], `${data?.menuImage}`, { type: res.headers['content-type'] }));
				})
				.then(() => {
					var file_list = dt.files;
					// console.log(file_list);
					setMenuImage(file_list);
				})
				.catch(err => err)

	}

	//Загрузка меню
	const dwnMenu = () => {
		const dt = new DataTransfer();
		// console.log(data.picture)

		const downloadBarImg =
			axios.get(`https://teatrcdr.renartdev.ru/teatr-api/img/cafe/menu/${data?.menu}`, {
				responseType: 'blob'
			})
				.then(res => {
					// console.log(res)
					// res.headers['content-type'].includes('image') &&
					dt.items.add(new File([res.data], `${data?.menu}`, { type: res.headers['content-type'] }));
				})
				.then(() => {
					var file_list = dt.files;
					// console.log(file_list);
					setMenu(file_list);
				})
				.catch(err => err)

	}

	//Загрузка меню бара
	const dwnBar = () => {
		const dt = new DataTransfer();
		// console.log(data.picture)

		const downloadBarImg =
			axios.get(`https://teatrcdr.renartdev.ru/teatr-api/img/cafe/menu/${data?.bar}`, {
				responseType: 'blob'
			})
				.then(res => {
					// console.log(res)
					// res.headers['content-type'].includes('image') &&
					dt.items.add(new File([res.data], `${data?.bar}`, { type: res.headers['content-type'] }));
				})
				.then(() => {
					var file_list = dt.files;
					// console.log(file_list);
					setBar(file_list);
				})
				.catch(err => err)

	}

	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={
						g1 ? handleSubmit(update) : handleSubmit(add)
					}>

					<SubTitle>Главное фото</SubTitle>
					<InputFileUI file={mainImage} setFile={setMainImage} name='mainImage' />

					<SubTitle>Описание</SubTitle>
					<div className={s.editor}>
						<TextEditor redactor={description} setRedactor={setDescription} placeholder='Описание' />
					</div>

					<SubTitle>Режим работы</SubTitle>
					<div className={s.editor}>
						<TextEditor redactor={workSchedule} setRedactor={setWorkSchedule} placeholder='Режим работы' />
					</div>

					<SubTitle>Фото для меню</SubTitle>
					<InputFileUI file={menuImage} setFile={setMenuImage} name='image1' />

					<SubTitle>ЦДР меню</SubTitle>
					<InputDocFileUI file={menu} setFile={setMenu} name='menu' />

					<SubTitle>Фото для бара</SubTitle>
					<InputFileUI file={barImage} setFile={setBarImage} name='image2' />

					<SubTitle>ЦДР бар</SubTitle>
					<InputDocFileUI file={bar} setFile={setBar} name='bar' />

					{/* <Controller
						name="text"
						render={({ field }) =>
							<TextField
								{...field}
								variant="outlined"
								label={"Текст"}
								size="small"
								fullWidth
								multiline
								rows={20}
								error={errors?.text?.message && true}
								helperText={errors?.text?.message ? errors?.text?.message : ' '} />}
						rules={{
							required: 'Поле обязательно для заполнения'
						}}
						control={control}
						defaultValue={data?.text ? data?.text : ''}
					/> */}

					<div className={s.button}>
						{/* <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
							<>Назад</>
						</Button> */}

						{/* {g1 !== undefined && <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => deletePerformance(id)}>Удалить</Button>} */}
						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Создать</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default CafeForm;
