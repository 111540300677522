import React from 'react';
import logo from './img/logo.jpg'
import s from './Logo.module.scss'
import cn from 'classnames'
import { Link } from "react-router-dom";

const Logo = ({ children, size, clickToOpenLeftMenu }) => {

	return (
		<>
			<Link to='./' onClick={clickToOpenLeftMenu}>
				<div className={cn(
					s.nameOrg,
					size === 'little' && s.little
				)
				}>
					<img src={logo} alt="Центр драматургиии режиссуры" />
					<div className={s.name}>{children}</div>
				</div>
			</Link>

		</>


	);
}

export default Logo;
