import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select'
import s from './AfishaPageSite.module.scss'
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import cn from 'classnames';
import MainTitle from '../../ui/MainTitle/MainTitle';
import moment from 'moment';
import 'moment/locale/ru';
import EventAPI from '../../utils/EventAPI';
import TicketlandAPI from '../../utils/TicketlandAPI';
import { useMediaQuery } from 'react-responsive';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
// import TLConf from 'index.html';


import * as utils from '../../utils/utils.js'



const AfishaPageSite = () => {
	moment.updateLocale('ru', {
		week: {
			dow: 1 // Monday is the first day of the week.
		}
	});
	// moment.updateLocale('ru', {
	// 	week: {
	// 		dow: 1 // Monday is the first day of the week.
	// 	}
	// });

	const filterScenesOptions = [
		{ value: '1', label: 'Все площадки' },
		{ value: 'сокол', label: 'Сокол' },
		{ value: 'беговая', label: 'Беговая' },
		{ value: 'поварская', label: 'Поварская' }
	]

	const filterScenesHash = [
		{ value: '1', hash: '' },
		{ value: 'сокол', hash: 'sokol' },
		{ value: 'беговая', hash: 'begovaya' },
		{ value: 'поварская', hash: 'povarskaya' }
	]

	const currentHash = window.location.hash.replace("#","");

	const currentSceneValue = filterScenesHash.filter((item) => item.hash == currentHash)[0].value;

	const defaultSceneValue = filterScenesOptions.filter((item) => item.value == currentSceneValue);


	const [currentMonth, setCurrentMonth] = useState(moment().format('YYYY-MM-DD'));
	const [currentScene, setCurrentScene] = useState(currentSceneValue);
	const [currentPushkin, setCurrentPushkin] = useState(0);
	const [calendarFormation, setCalendarFormation] = useState(false);
	const [events, setEvents] = useState([]);
	const [ticketlandEvents, setTicketlandEvents] = useState([]);
	const [script, setScript] = useState('');
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [url, setUrl] = useState('');
	const days = [];
	const week = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
	const isMobile = useMediaQuery({ query: `(max-width: 480px)` });
	const isMounted = useRef(false);

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '90%',
		height: '85%',
		bgcolor: 'background.paper',
		padding: '5px 5px'
	};



	useEffect(() => {
		setLoading(false);
		setEvents([]);
		const start = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
		const end = moment(currentMonth).endOf('month').add(1, 'day').format('YYYY-MM-DD');
		EventAPI.getEventsForAfisha(start, end)
			.then(result => {
				if (result.status === 200) {
					setEvents(result.data)
					setLoading(true)
				}

				if (result.response?.status === 406) {
					// //console.log(result.response.data)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
					setLoading(true)
				}
			})
		// .then(() => {
		// 	TicketlandAPI.getEventTicketland()
		// 		.then(result => {
		// 			if (result.status === 200) {
		// 				setTicketlandEvents(result.data.message)
		// 				//console.log(result.data.message)
		// 				// setLoading(true)
		// 			}

		// 			if (result.response?.status === 406) {
		// 				// //console.log(result.response.data)
		// 				// setLoading(true)
		// 			}

		// 			if (result.response?.status === 500) {
		// 				// //console.log(result.response.data)
		// 				// setLoading(true)
		// 			}
		// 		})
		// })
	}, [currentMonth]);

	// useEffect(() => {
	// 	TicketlandAPI.getEventTicketland()
	// 		.then(result => {
	// 			if (result.status === 200) {
	// 				// setEvents(result.data)
	// 				//console.log(result.data)
	// 				setLoading(true)
	// 			}

	// 			if (result.response?.status === 406) {
	// 				// //console.log(result.response.data)
	// 				setLoading(true)
	// 			}

	// 			if (result.response?.status === 500) {
	// 				// //console.log(result.response.data)
	// 				setLoading(true)
	// 			}
	// 		})
	// }, []);


	//Формируем дни
	const firstDate = moment(currentMonth).startOf('month');
	const lastDate = moment(currentMonth).endOf('month');

	// //console.log(firstDate);
	// //console.log(firstDate);


	while (firstDate.isBefore(lastDate + 1, 'day')) {
		days.push({ day: firstDate.format('D'), dayOfWeek: firstDate.day(), month: firstDate.format('MM'), date: firstDate.format('YYYY-MM-DD HH:mm:ss') });
		firstDate.add(1, 'day');
	}

	let firstDayOfWeek = days[0]?.dayOfWeek;
	// //console.log(firstDayOfWeek)

	if (firstDayOfWeek === 0) {
		days.unshift({ day: "", dayOfWeek: 6, month: "", date: '1990-01-01 00:00:00' })
		days.unshift({ day: "", dayOfWeek: 5, month: "", date: '1990-01-01 00:00:00' })
		days.unshift({ day: "", dayOfWeek: 4, month: "", date: '1990-01-01 00:00:00' })
		days.unshift({ day: "", dayOfWeek: 3, month: "", date: '1990-01-01 00:00:00' })
		days.unshift({ day: "", dayOfWeek: 2, month: "", date: '1990-01-01 00:00:00' })
		days.unshift({ day: "", dayOfWeek: 1, month: "", date: '1990-01-01 00:00:00' })
	} else {
		firstDayOfWeek = firstDayOfWeek - 1;
		while (firstDayOfWeek > 0) {
			days.unshift({ day: "", dayOfWeek: firstDayOfWeek, month: "", date: '1990-01-01 00:00:00' })
			firstDayOfWeek--
		}
	}

	let lastDayOfWeek = days[days.length - 1]?.dayOfWeek + 1;
	// let lastDayOfWeek = 0;
	// //console.log(lastDayOfWeek)

	while (lastDayOfWeek <= 7) {
		if (lastDayOfWeek === 7) {
			days.push({ day: "", dayOfWeek: lastDayOfWeek - 7, month: "", date: '1990-01-01 00:00:00' })
		} else {
			days.push({ day: "", dayOfWeek: lastDayOfWeek, month: "", date: '1990-01-01 00:00:00' })
		}
		lastDayOfWeek++
	}

	const monthAhead = () => {
		setCurrentMonth(prev => moment(prev).add(1, 'month'))
	}

	const monthAgo = () => {
		setCurrentMonth(prev => moment(prev).subtract(1, 'month'))
	}



	const filterSelectScene = (choice) => {
		setCurrentScene(choice.value)

		let hash = filterScenesHash.filter((item) => item.value == choice.value)[0].hash;

		window.location.hash = hash;

	}

	const filterSelectPushkin = () => {
		setCurrentPushkin(document.getElementById('afisha_filter_pushkin').checked ? 1 : 0)
	}

	// const testOpen = () => {
	// 	window.TLIframe.showPopup({url: 'https://www.ticketland.ru/teatry/centr-dramaturgii-i-rezhissury/ya-ona-ne-ya-i-ya/20240113_1900-2293545'})
	// }

	const handleOpen = (url) => {
		setUrl(url);
		// setOpenModal(true);
		window.TLIframe.showPopup({url: url})
	}

	const handleClose = () => {
		setUrl('');
		setOpenModal(false);
	}

	// //console.log(months)
	//console.log(days)
	//console.log(events)

	const filter = days?.filter(item => {
		return events.some(event => {
			return moment(item.date).isSame(moment(event.eventDate), 'day')
		})
	})

	const desktopRender =
		<>
			{loading === true ?
				<>
					{days.map((item, index) => {
						return <div
							className={cn(s.dayItem,
								item.day === '' && s.disabled,
								(item.day === moment().format('D') && item.month === moment().format('MM')) && s.currentDay
							)}
							key={index + item.day + item.month}>
							<div className={s.upLine}>
								<div className={cn(s.dayOfWeek,)}>{week[item.dayOfWeek]}</div>
								{item.day && <p className={s.dayNumber}>{utils.zeroPad(item.day, 2)}</p>}
							</div>
							{/* <p className={s.opaciti}>Lorem ipsum dolor sit amet consectetur</p> */}

							<div className={s.events}>
								{events.map((event) => {

									//console.log(event)

									if (moment(item.date).isSame(moment(event.eventDate), 'day')
										&& (event.area.toLowerCase() == currentScene || currentScene == "1")
										&& (event.pushkin == currentPushkin || currentPushkin == 0)
									) {

										return (
											<>
												<div
													className={cn(
														s.event,
														// TODO event.soldOut нужно брать с бэка
														event.soldOut == 1 && s.eventSold
													)}
													key={event.eventID}
													onClick={() => event.soldOut != 1 && handleOpen(event.ticketland)}
												>
													<p>{moment(event.eventDate).format('HH:mm')}</p>
													<p>{event.area}</p><br />
													<p className={s.perfName}>{event.performanceName}</p>
													{event.eventDateTransfer !== '0000-00-00 00:00:00' && <div className={s.transfer}>
														{moment(event.eventDateTransfer).format('Перенос с DD.MM.YYYY')}
													</div>}

													{event.soldOut == 1 && (
														<div className={s.soldNote}>
															<div>Билеты проданы</div>
														</div>
													)}

												</div>

											</>


										)
									}
								})}
							</div>

						</div>
					})}
				</>
				: <CircularProgress />
			}

		</>

	const mobileRender =
		<>
			{filter.length === 0 && <p className={s.notFound}>Спектаклей не найдено</p>}
			{filter.map((item, index) => {
				return <div
					className={cn(s.dayItem,
						item.day === '' && s.disabled,
						(item.day === moment().format('D') && item.month === moment().format('MM')) && s.currentDay
					)}
					key={index}>
					<div className={s.upLine}>
						<div className={cn(s.dayOfWeek,)}>{week[item.dayOfWeek]}</div>
						{item.day && <p className={s.dayNumber}>{item.day}</p>}
					</div>
					{/* <p className={s.opaciti}>Lorem ipsum dolor sit amet consectetur</p> */}
					<div className={s.events}>
						{events.map((event) => {
							if (moment(item.date).isSame(moment(event.eventDate), 'day')
								&& (event.area.toLowerCase() == currentScene || currentScene == "1")
								&& (event.pushkin == currentPushkin || currentPushkin == 0)) {
								return (

									<div
										className={cn(
											s.event,
											event.soldOut == 1 && s.eventSold
										)}
										key={event.eventID}
										onClick={() => handleOpen(event.ticketland)}
									>
										<p>{moment(event.eventDate).format('HH:mm')}</p>
										<p>{event.area}</p><br />

										<p className={s.perfName}>{event.performanceName}</p>
										{event.eventDateTransfer !== '0000-00-00 00:00:00' && <div className={s.transfer}>
											{moment(event.eventDateTransfer).format('Перенос с DD.MM.YYYY')}
										</div>}

										{event.soldOut == 1 && (
											<div className={s.soldNote}>
												<div>Билеты проданы</div>
											</div>
										)}

									</div>

								)
							}
						})}
					</div>
				</div>
			})}
		</>

	// const openModalTicketland = (script) => {
	// 	let oldScript = document.body.querySelectorAll('script');
	// 	//console.log(oldScript)
	// 	if (oldScript.length>0) {
	// 		oldScript.forEach(item => item.remove())
	// 	}
	// 	let aScript = document.createElement(script);
	// 	aScript.type = 'text/javascript';
	// 	// aScript.text = `${script}`;

	// 	document.body.append(aScript);
	// 	setOpenModal(true);
	// }



	//console.log(window.location.href)



	return (
		<div className={s.afishaPage}>
			<HelmetUI title="Афиша - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />


			<MainTitle>Афиша</MainTitle>
			<div className={s.afishaFilter}>
				<div className={s.afishaFilter_row}>
					<div className={s.afishaFilter_col}>
						<div className="form-group">
							<Select
								id="afisha_filter_scene"
								options={filterScenesOptions}
								isSearchable={false}
								defaultValue={defaultSceneValue}
								onChange={(choice) => filterSelectScene(choice)}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										borderRadius: 0,
										borderColor:'#37246A !important',
										outline: 'none !important',
										boxShadow: 'none !important',
										textTransform: 'uppercase',
										height: 50,
										cursor: "pointer",
										"@media (max-width: 768px)": {
											...baseStyles["@media (max-width: 768px)"],
											fontSize: "12px"
										},
									}),
									indicatorsContainer: (provided) => ({
										...provided,
										"span": {
											display: 'none !important'
										},
										"div": {
											color: '#37246A',
											width: 48,
											height: 48,
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}
									}),
									valueContainer: (provided) => ({
										...provided,
										paddingLeft: 25,
										"@media (max-width: 768px)": {
											...provided["@media (max-width: 768px)"],
											paddingLeft: 15,
										},
									}),
									menu: (provided) => ({
										...provided,
										borderRadius: 0,
										border: "none !important",
										outline: "none !important",
										boxShadow: "0px 0px 20px 0px #0000001A",
										marginTop: 0,
										marginBottom: 0,
										padding: 0,
										"@media (max-width: 768px)": {
											...provided["@media (max-width: 768px)"],
											padding: "5px 0",
										},
									}),
									menuLust: (provided) => ({
										...provided,
										padding: 0
									}),
									option: (provided, state) => ({
										...provided,
										textTransform: "uppercase",
										padding: "6px 26px",
										backgroundColor: "transparent !important",
										color: state.isSelected ? "#37246A" : "#333333",
										fontWeight: state.isSelected ? "bold" : "normal",
										cursor: "pointer",
										"@media (max-width: 768px)": {
											...provided["@media (max-width: 768px)"],
											fontSize: "12px",
											padding: "10px 16px",
										},
									}),
								}}
							/>

						</div>
					</div>
					<div className={s.afishaFilter_col}>
						<div className={s.formGroup}>
							<div className={s.formCheckbox}>
								<input type="checkbox" name="afisha_filter_pushkin" id="afisha_filter_pushkin" onChange={() => filterSelectPushkin()}/>
								<label htmlFor="afisha_filter_pushkin">Пушкинская карта</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <button class="tl_button" onclick={()=>testOpen()}>Купить билет</button> */}

			<div className={s.monthPanel}>
				<button onClick={() => monthAgo()}><KeyboardDoubleArrowLeftIcon /></button>
				<div className={s.monthItem}>
					{moment(currentMonth).format('MMMM YYYY')}
				</div>
				<button onClick={() => monthAhead()}><KeyboardDoubleArrowRightIcon /></button>

			</div>


			<div >
				<div className={s.daysPanel}>
					{isMobile && loading === true ? mobileRender : desktopRender}

				</div>
			</div>

			{/* <Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: -12,
							top: -40,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<iframe src={url} title='ticketland' height='100%' width='100%' />
					


				</Box>
			</Modal> */}
			{/* </div> */}
			{/* </Fade> */}

		</div>

	);
}

export default AfishaPageSite;
