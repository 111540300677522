import React, { useState, useEffect } from 'react';
import s from './CafePageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Routes, Route } from "react-router-dom";
import CafeAPI from '../../utils/CafeAPI';


const CafePageSite = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		CafeAPI.getCafe()
			.then(result => {
				// //console.log(result.data);
				if (result.status === 200) {
					setData(result.data[0])
					// setImgArray(result.data[0].picture)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	// //console.log(data)
	return (
		<div>
			<div className={s.container}>
				<Routes>
					<Route index element={<HelmetUI title="ЦДР Кафе - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
				</Routes>

				<div className={s.content}>
					<MainTitle>ЦДР Кафе</MainTitle>
					{loading === true &&
						<>
							<div className={s.flex_container}>
								<div>
									<div className={s.img}>
										<img src={'https://teatrcdr.renartdev.ru/teatr-api/img/cafe/img/' + data?.picture} alt="История ЦДР" />
									</div>
								</div>
								<div className={s.description}>

									<div className={s.text} dangerouslySetInnerHTML={{ __html: data.description }}></div>
									<br />
									<MainTitle>Режим работы</MainTitle>
									<div>
										<div className={s.text} dangerouslySetInnerHTML={{ __html: data.workSchedule }}></div>
									</div>
									<br />
									<MainTitle>Контакты</MainTitle>
									8 (980) 463-64-58
								</div>
							</div>

							{data?.menu &&
								<div className={s.flex_container}>
									<div className={s.docTitle}>
										<a href={'https://teatrcdr.renartdev.ru/teatr-api/img/cafe/menu/' + data?.menu}>ЦДР Меню</a>
									</div>
									<div className={s.img}>
										<img src={'https://teatrcdr.renartdev.ru/teatr-api/img/cafe/menuImage/' + data?.menuImage} alt="История ЦДР" />
									</div>
								</div>
							}

							{data?.bar &&
								<div className={s.flex_container}>
									<div className={s.img}>
										<img src={'https://teatrcdr.renartdev.ru/teatr-api/img/cafe/barImage/' + data?.barImage} alt="История ЦДР" />
									</div>
									<div className={s.docTitle}>
										<a href={'https://teatrcdr.renartdev.ru/teatr-api/img/cafe/bar/' + data?.bar}>ЦДР Бар</a>
									</div>

								</div>
							}
						</>



					}

				</div>
			</div>
		</div >
	);
}

export default CafePageSite;
