import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import s from './OneTeamInformation.module.scss'
import Fade from 'react-reveal/Fade';
import {useParams} from "react-router-dom";
import EmployeesAPI from '../../../utils/EmployeesAPI';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import Grid from '@mui/material/Unstable_Grid2';
import ShowSlider from '../../../ui/ShowSlider/ShowSlider';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';


const OneTeamInformation = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const isMobile = useMediaQuery({ query: `(max-width: 425px)` });
	const { id } = useParams()

	useEffect(() => {
		EmployeesAPI.getEmployeeForIdSite(id, 'team')
			.then(result => {
				// //console.log(result.data[0]);
				if (result.status === 200) {
					setData(result.data[0])
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	// //console.log(data)

	return (
		<div>
			<div className={s.title}>
				<MainTitle>{data?.fio?.toUpperCase()}</MainTitle>
			</div>
			<HelmetUI title={data?.fio + " - Центр драматургии и режиссуры"} description="Театр Центр драматургии и режиссуры" />

			<div className={s.description}>
				{data.description}
			</div>

			{data?.performanceList?.length > 0 && <div className={s.Shows}>
				<Grid container>
					<Grid xs={12} sm={12} sx={{ pb: '15px', mt: '15px', pt: '15px' }}>
						<ShowSlider data={data} />
					</Grid>
				</Grid>
			</div>
			}

		</div>
	);
}

export default OneTeamInformation;
