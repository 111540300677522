import React, { useState, useEffect } from 'react';
import s from './EmployeesList.module.scss';
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import EmployeesAPI from '../../../../utils/EmployeesAPI'
import Search from '../../../Search/Search';
import Pagination from '@mui/material/Pagination';
import ListItem from '../../../ListItem/ListItem';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EmployeesListItem from '../EmployeesListItem/EmployeesListItem';

const EmployeesList = () => {
	const [employees, setEmployees] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchRequest, setSearchRequest] = useState('');
	const [type, setType] = useState('artists');
	const [page, setPage] = useState(1);
	const [maxPage, setMaxPage] = useState(0);
	const [maxListItem] = useState(10);
	const [beginIndex, setBeginIndex] = useState(0)
	const [endIndex, setEndIndex] = useState(10)

	useEffect(() => {
		if (searchRequest !== '') {
			setMaxPage(0);
		} else {
			setMaxPage(Math.ceil(employees.length / maxListItem))
		}
	}, [searchRequest, maxListItem, employees])

	useEffect(() => {
		loading === false &&
			EmployeesAPI.getEmployee(type)
				.then(result => {
					//console.log(result);
					if (result.status === 200) {
						setEmployees(result.data)
						setMaxPage(Math.ceil(result.data.length / maxListItem))
						setBeginIndex(0)
						setEndIndex(10)
						setPage(1)
						setSearchRequest('')
						setLoading(true)
					}

					if (result.response?.status === 406) {
						//console.log(result.response.data)
						setEmployees([])
						setPage(1)
						setLoading(true)
					}

					if (result.response?.status === 500) {
						//console.log(result.response.data)
					}
				})
	}, [loading, maxListItem, type]);

	const filter = employees?.filter((user) => {
		return (
			user?.fio?.toLowerCase().includes(searchRequest.toLowerCase()) ||
			user?.profession?.toLowerCase().includes(searchRequest.toLowerCase())
		)
	})

	const showUsersByPage = filter?.map((item, index) => {
		if (index >= beginIndex && index < endIndex) {
			return (
				<Link to={`./${type}/${item?.empID}`
				} key={item?.empID}>
					<EmployeesListItem data={item} type={type} />
				</Link>
			)
		}
	})

	const showUsersWhithoutLimits = filter?.map((item) => {
		return (
			<Link to={`./${type}/${item?.empID}`
			} key={item?.empID}>
				<EmployeesListItem data={item} type={type} />
			</Link>
		)
	})

	const changePage = (event, value) => {
		setBeginIndex(value * maxListItem - maxListItem);
		setEndIndex(value * maxListItem)
		setPage(value);
	}

	const [value, setValue] = React.useState('1');

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const renderList = () => {
		return (
			<>
				<Box sx={{ width: '100%', typography: 'body1' }}>
					<TabContext value={value}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable"
								scrollButtons="auto">
								<Tab label="Артисты" value="1" onClick={() => { setType('artists'); setLoading(false) }} />
								<Tab label="Команда" value="2" onClick={() => { setType('team'); setLoading(false) }} />
								{/* <Tab label="Контакты" value="3" onClick={() => { setType('contacts'); setLoading(false) }} /> */}
							</TabList>
						</Box>
						<TabPanel value="1" sx={{ padding: '0px' }}>
							<div className={s.search}>
								<Search setSearchRequest={setSearchRequest} />
							</div>

							<div className={s.container}>
								{maxPage !== 0
									? showUsersByPage
									: showUsersWhithoutLimits}
								{showUsersByPage.length === 0 && <>Нет записей...</>}
							</div>

							<div className={s.pagination}>
								{((maxPage !== 1 && employees.length !== 0) && (maxPage !== 0 && employees.length !== 0)) &&
									<Pagination sx={{ button: { borderRadius: '0px', color: 'text.primary' }, ul: {display: 'flex', gap: '1px'} }} count={maxPage} page={page} variant="outlined" size='large' shape="rounded" boundaryCount={0} siblingCount={1} hidePrevButton hideNextButton onChange={changePage} />
								}
							</div>
						</TabPanel>
						<TabPanel value="2" sx={{ padding: '0px' }}>
							<div className={s.search}>
								<Search setSearchRequest={setSearchRequest} />
							</div>

							<div className={s.container}>
								{maxPage !== 0
									? employees === [] ? <>Нет записей</> : showUsersByPage
									: showUsersWhithoutLimits}
								{showUsersByPage.length === 0 && <>Нет записей...</>}
							</div>

							<div className={s.pagination}>
								{((maxPage !== 1 && employees.length !== 0) && (maxPage !== 0 && employees.length !== 0)) &&
									<Pagination sx={{ button: { borderRadius: '0px', color: 'text.primary' }, ul: {display: 'flex', gap: '1px'} }} count={maxPage} page={page} variant="outlined" size='large' shape="rounded" boundaryCount={0} siblingCount={1} hidePrevButton hideNextButton onChange={changePage} />
								}
							</div>
						</TabPanel>
						<TabPanel value="3" sx={{ padding: '0px' }}>
							<div className={s.search}>
								<Search setSearchRequest={setSearchRequest} />
							</div>

							<div className={s.container}>
								{maxPage !== 0
									? employees.length === 0 ? <>Нет записей</> : showUsersByPage
									: showUsersWhithoutLimits}
								{showUsersByPage.length === 0 && <>Нет записей...</>}
							</div>

							<div className={s.pagination}>
								{((maxPage !== 1 && employees.length !== 0) && (maxPage !== 0 && employees.length !== 0)) &&
									<Pagination sx={{ button: { borderRadius: '0px', color: 'text.primary' }, ul: {display: 'flex', gap: '1px'} }} count={maxPage} page={page} variant="outlined" size='large' shape="rounded" boundaryCount={0} siblingCount={1} hidePrevButton hideNextButton onChange={changePage} />
								}
							</div>
						</TabPanel>
					</TabContext>
				</Box>


			</>

		)
	}


	return (
		<div>
			{loading === true
				?
				renderList()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default EmployeesList;
