import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.renartdev.ru/teatr-api';
	}

	//Поиск
	get(searchRequest) {
		return axios.get(`${this._baseUrl}/search`, {
			params: {
				searchRequest: searchRequest
			}
		})
			.then(res => res)
			.catch(err => err)
	}
}

const SearchAPI = new Api();

export default SearchAPI;