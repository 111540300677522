import React from 'react';
import s from "./PartnersPage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import InformationForm from '../../../components/Admin/Information/InformationForm/InformationForm';
import PartnersForm from '../../../components/Admin/Partners/PartnersForm/PartnersForm';

const PartnersPage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="Партнеры" description="Управление страницей партнеры" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Партнеры</MainTitle>} />
			</Routes>

			<div className={s.content}>
				<Routes>
					<Route index element={<PartnersForm g1={true}/>} />
				</Routes>
			</div>
		</>
	);
}

export default PartnersPage;
