import React, { useEffect } from 'react';
import s from './SearchMainPage.module.scss'
import TextField from '@mui/material/TextField';
import { useForm, Controller } from "react-hook-form";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";

const SearchMainPage = ({ request }) => {
	// const [searchRequest, setsearchRequest] = useState('');
	const { control, handleSubmit, setValue } = useForm({ mode: "onSubmit" });
	const navigate = useNavigate();

	const setSearchRequest = (data) => {
		// console.log(control.register)
		if (data.search.length > 0) {
			navigate(`/search?request=${data.search}`)
			setValue('search', '');
		}

	}


	useEffect(() => {
		setValue('search', request);

		const keyDownHandler = event => {
			if (event.key === 'Enter') {
				handleSubmit((data) => setSearchRequest(data.search))
			}
		};

		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	});



	return (

		<div className={s.search}>
			{window.location.pathname !== '/search' &&
				<form className={s.form} onSubmit={handleSubmit((data) => setSearchRequest(data))}>
					<Controller
						render={({ field }) => <TextField sx={{ label: { fontSize: '12px' }, input: { p: 0 } }} {...field} variant="standard" label="Поиск" size="small" fullWidth />}
						name="search"
						control={control}
						defaultValue={request && request}
					/>
					<IconButton type="submit" sx={{ p: '1px', borderRadius: '0px', border: '1px solid #ddd', marginTop: '16px' }} aria-label="search">
						<SearchIcon sx={{ fontSize: '18px' }} />
					</IconButton>
				</form>
			}

		</div>

	);
}

export default SearchMainPage;
