import React, { useState } from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { NavLink } from "react-router-dom";
import s from './SiteMenu.module.scss'
import cn from 'classnames'
import SiteMenuItem from "../SiteMenuItem/SiteMenuItem";

const SiteMenu = () => {



	return (
		<>
			<div className={s.menu}>
				<ul className={s.mainMenu}>
					<SiteMenuItem
						name='Афиша'
						url='/afisha'
						submenu={[]}
					/>
					<SiteMenuItem
						name='Спектакли'
						url='/spektakli'
						submenu={[]}
					/>
					<SiteMenuItem
						name='О&nbsp;театре'
						url=''
						submenu={
							[
								{
									name: 'История',
									url: '/o-teatre/istoriya'
								},
								{
									name: 'Soundrama',
									url: '/o-teatre/soundrama'
								},
								{
									name: 'Лаборатории',
									url: '/o-teatre/laboratorii'
								},
								{
									name: 'События',
									url: '/sobytiya'
								},
								{
									name: 'Купить билеты',
									url: '/kupit-bilety'
								},
								{
									name: 'Партнёры',
									url: '/o-teatre/partnery'
								},
								{
									name: 'Официальная информация',
									url: '/o-teatre/oficialnaya-informaciya'
								},
								{
									name: 'Архив спектаклей',
									url: '/o-teatre/archive'
								}
							]
						}
					/>
					<SiteMenuItem
						name='Люди театра'
						url=''
						submenu={
							[
								{
									name: 'Артисты',
									url: '/o-teatre/artisty'
								},
								{
									name: 'Команда',
									url: '/o-teatre/komanda'
								}
							]
						}
					/>
					<SiteMenuItem
						name='Проекты'
						url='/o-teatre/proekty'
						submenu={
							[
								{
									name: 'ЦДР. вечера',
									url: '/o-teatre/proekty/nB-1JrDabOIEQ5WO-Mlel'
								},
								{
									name: 'ЦДР детям',
									url: '/o-teatre/proekty/i3MNkiBmxI_H51wNJOjLv'
								},
								{
									name: 'ЦДР. старт',
									url: '/o-teatre/proekty/1YBqCUkpNnOsQvFlUIpKx'
								},
								{
									name: 'ЦДР. диалог',
									url: '/o-teatre/proekty/Ruqdb-VMtHgzxkYYfVYOi'
								},
								{
									name: 'ЦДР. live',
									url: '/o-teatre/proekty/9A8VWnpq6JGAe05eooSd9'
								},
								{
									name: 'Поварская, 20',
									url: '/o-teatre/proekty/mE4cz_MmhCW2tLZ8fhkbi'
								},



								{
									name: 'ЦДР кафе',
									url: '/o-teatre/cdr-cafe'
								}
							]
						}
					/>
					<SiteMenuItem
						name='Площадки'
						url='/o-teatre/ploshhadki'
						submenu={[]}
					/>
					<SiteMenuItem
						name='Контакты'
						url='/kontakty'
						submenu={[]}
					/>
				</ul>
			</div>
		</>
	);
}

export default SiteMenu;
