import React, { useEffect, useState } from 'react';
import s from "./TicketForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PerformanceAPI from '../../../../utils/PerformanceAPI';
import PageAPI from '../../../../utils/PageAPI';
import NewsAPI from '../../../../utils/NewsAPI';
import InputFileUI from '../../../../ui/InputFileUI/InputFileUI';
import axios from 'axios'
import TextEditor from '../../../../ui/TextEditor/TextEditor';


const inflection = require('inflection')

const TicketForm = ({ g1 }) => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState([]);
	const [imgArray, setImgArray] = useState([]);
	const [loadingFiles, setloadingFiles] = useState(false);
	const [prew, setPrew] = useState([]);
	const [show, setShow] = useState([]);
	const { id } = useParams()
	const navigate = useNavigate();
	const [redactor, setRedactor] = useState();


	const { control, handleSubmit, formState: { errors, isDirty, isValid }, setValue, getValues } = useForm(
		{
			mode: "onSubmit"
		}
	);

	//console.log(redactor)

	useEffect(() => {
		PageAPI.getPage('ticket')
			.then(result => {
				// //console.log(result.data);
				if (result.status === 200) {
					setData(result.data[0])
					setRedactor(result.data[0].text)
					setImgArray(result.data[0].picture)
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	useEffect(() => {
		// setValue('newsID', nanoid());
		// setValue('picture[]', file);
		setValue('name', 'ticket');
	}, [setValue, data, loading, file, setImgArray]);


	//Изменить событие
	const update = (form) => {
		form.text = redactor;

		//console.log(form)
		PageAPI.updatePage(form)
			.then(result => {
				// //console.log(result);
				if (result.status === 200) {
					// navigate(-1)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}

	//Отрисовка формы
	const renderForm = () => {
		return (
			<>
				<form
					className={s.form}
					onSubmit={handleSubmit(update)}>

					<TextEditor redactor={redactor} setRedactor={setRedactor} placeholder='Начните вводить содержимое страницы...'/>

					{/* <div className={s.editor}>
						<br></br>
						<ReactQuill theme="snow" modules={toolbarOptions} value={redactor} onChange={setRedactor} />
					</div> */}

					<div className={s.button}>
						<Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => navigate(-1)}>
							<>Назад</>
						</Button>

						<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
							{g1 !== undefined ? <>Изменить</> : <>Создать</>}
						</Button>
					</div>

				</form>
			</>
		)
	}

	return (
		<div className={s.container}>
			{loading === true
				?
				renderForm()
				:
				<CircularProgress />
			}
		</div>
	);
}

export default TicketForm;
