import React, { useState, useEffect } from 'react';
import s from './ShowSlider.module.scss'
import MainTitle from '../MainTitle/MainTitle';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import 'swiper/css/navigation';
import 'swiper/css/pagination';

const ShowSlider = ({ data }) => {

	const [slideView, setSlideView] = useState(4);

	const isMobile = useMediaQuery({ query: `(max-width: 890px)` });
	const isLaptop = useMediaQuery({ query: `(max-width: 1024px)` });
	const isDesktop = useMediaQuery({ query: `(max-width: 1440px)` });
	const imgPathShow = "https://teatrcdr.renartdev.ru/teatr-api/img/performance/";

	useEffect(() => {
		// //console.log(isMobile)
		if (isMobile) {
			setSlideView(1)
		} else if(isLaptop) {
			setSlideView(2)
		} else if (isDesktop) {
			setSlideView(4)
		}
	}, [isMobile]);

	// //console.log(data)

	return (
		<div>
			<MainTitle>Спектакли</MainTitle>
			<Swiper
				slidesPerView={slideView}
				spaceBetween={10}
				navigation
				modules={[Navigation]}
				className={s.ShowListContainer}
				style={{
					'--swiper-navigation-color': '#fff',
					'--swiper-pagination-color': '#fff',
				}}
			>
				{data?.performanceList?.map(show => {
					return <SwiperSlide key={show.showID}>
						<div className={s.showItem}>
							<div className={s.showItemImg}>
								<img src={imgPathShow + show.showID + '/' + show.picture[0]} alt="" />
							</div>
							<Link to={`/spektakli/${show?.showID}`} >
								<div className={s.showName}>{show?.name?.length > 80 ? show?.name?.slice(0, 70) + '...' : show?.name}</div>
							</Link>
						</div>
					</SwiperSlide>
				})}
			</Swiper>
		</div>
	);
}

export default ShowSlider;
