import React from 'react';
import s from './Social.module.scss'
import telegram from './img/telegram.jpg'
import vk from './img/vk.png'
import mail from './img/mail.png'
import { Link } from 'react-router-dom'

const Social = () => {
	return (
		<div className={s.social}>
			<Link to='https://t.me/teatrcdr' target='_blank'>
				<img src={telegram} />
			</Link>
			<Link to='https://vk.com/cdr_theatre' target='_blank'>
				<img src={vk} />
			</Link>
			<Link to='mailto:MelnikovaEP@culture.mos.ru' target='_blank'>
				<img src={mail} />
			</Link>
		</div>
	);
}

export default Social;
