import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import s from './NewsInformation.module.scss'
import EmployeesAPI from '../../../utils/EmployeesAPI';
import { useNavigate, useParams, Link } from "react-router-dom";
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Scrollbar, Navigation, Pagination } from "swiper";
import Grid from '@mui/material/Unstable_Grid2';
import cn from 'classnames'
import NewsAPI from '../../../utils/NewsAPI';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ShowSlider from '../../../ui/ShowSlider/ShowSlider';

const NewsInformation = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [slideView, setSlideView] = useState(3);
	const { id } = useParams()

	const imgPath = "https://teatrcdr.renartdev.ru/teatr-api/img/news/"
	const imgPathShow = "https://teatrcdr.renartdev.ru/teatr-api/img/performance/"

	const isMobile = useMediaQuery({ query: `(max-width: 425px)` });

	useEffect(() => {
		NewsAPI.getNewsForId(id)
			.then(result => {
				// //console.log(result.data[0]);
				if (result.status === 200) {
					setData(result.data[0])
					setLoading(true)
				}

				if (result.response?.status === 500) {
					// //console.log(result.response.data)
				}
			})
	}, []);

	useEffect(() => {
		if (isMobile) {
			setSlideView(1)
		} else {
			setSlideView(3)
		}
	}, [isMobile]);

	return (
		<>
			{loading === true &&
				<div className={cn(s.showInformation, data.picture.length === 0 && s.upMargin)} >
					<HelmetUI title={data.title} description={data.title + " - Центр драматургии и режиссуры"} />
					{data.picture.length !== 0 &&
						<div className={s.carousel}>
							<Swiper
								spaceBetween={30}
								effect={"fade"}
								scrollbar={{
									hide: true,
								}}
								autoplay={{
									delay: 5000,
									disableOnInteraction: false,
								}}
								loop={true}
								modules={[EffectFade, Autoplay, Scrollbar]}
								// className={s.imageContainer}
							>
								{data?.picture?.map(image => {
									return <SwiperSlide key={image}>
										<div className={s.carouselItem}>
											<img className={s.carouselImg} key={image} src={imgPath + id + '/' + image} alt={image} />
										</div>

									</SwiperSlide>
								})}
							</Swiper>
						</div>
					}


					<Grid container>

						<Grid xs={12} sm={12} sx={{ pr: '15px' }}>
							<MainTitle>{data.title.toUpperCase()}</MainTitle>

							{data.description !== "" &&
								<div className={s.description}>
									<div className={s.additionallyText} dangerouslySetInnerHTML={{ __html: data.text }}></div>
								</div>
							}
						</Grid>

						{data?.performanceList.length > 0 && <Grid xs={12} sm={12} sx={{ pb: '15px', mt: '15px', pt: '15px' }}>
							<ShowSlider data={data} />
						</Grid>}


					</Grid>


				</div >
			}
		</>
	);
}

export default NewsInformation;
