import React from 'react';
import s from './ShowListItem.module.scss'
const inflection = require('inflection')

const ShowListItem = ({ data }) => {


	return (
		<div className={s.line} >
			<div className={s.fio}>{inflection.titleize(data?.name)}</div>
			<div className={s.login}>
				Спектакль
			</div>
		</div>
	);
}

export default ShowListItem;
