import React from 'react';
import { Outlet } from "react-router-dom";

const Access = () => {

	return (
		<>
			<Outlet />
		</>
	);
}

export default Access;
