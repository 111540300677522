import React from 'react';
import s from "./InformationPage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import InformationForm from '../../../components/Admin/Information/InformationForm/InformationForm';

const InformationPage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="Официальная информация" description="Управление страницей официальная информация" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Официальная информация</MainTitle>} />
			</Routes>

			<div className={s.content}>
				<Routes>
					<Route index element={<InformationForm g1={true}/>} />
				</Routes>
			</div>
		</>
	);
}

export default InformationPage;
