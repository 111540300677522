import React from 'react';
import s from './AfishaListItem.module.scss'
import { format, compareAsc } from 'date-fns'

const AfishaListItem = ({ data }) => {


	return (
		<div className={s.line} >
			<div className={s.fio}>{data?.performanceName}</div>
			<div className={s.login}>
				{format(new Date(data?.eventDate), 'dd.MM.yyyy в HH:mm')}
			</div>
		</div>
	);
}

export default AfishaListItem;
