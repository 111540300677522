import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.renartdev.ru/teatr-api';
	}

	//Добавить лабораторию
	addArea(data) {
		return axios.post(`${this._baseUrl}/area`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Удалить лабораторию
	deleteArea(id) {
		return axios.delete(`${this._baseUrl}/area/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Получить лаборатории
	getArea() {
		return axios.get(`${this._baseUrl}/area`)
			.then(res => res)
			.catch(err => err)
	}
	
	//Получить лабораторию по ID
	getAreaForId(id) {
		return axios.get(`${this._baseUrl}/area/${id}`)
			.then(res => res)
			.catch(err => err)
	}

	//Изменить лабораторию
	updateArea(id, data) {
		return axios.post(`${this._baseUrl}/area/${id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

}

const AreaAPI = new Api();

export default AreaAPI;