import React from 'react';
import s from './EmployeesListItem.module.scss'
const inflection = require('inflection')

const EmployeesListItem = ({ data, type }) => {


	return (
		<div className={s.line} >
			<div className={s.fio}>
				{type === 'contacts' ? data?.position : inflection.titleize(data?.fio) }
			</div>
			<div className={s.login}>
				{/* {inflection.titleize(data?.)} */}
				{(type === 'artists' && data?.troupe === '1') && <>Труппа</>}
				{(type === 'artists' && data?.troupe === '0') && <>Пригл. артисты</>}
				{type === 'team' ? inflection.titleize(data?.profession) : false}
				{type === 'contacts' ? <>Контакт</> : false}
			</div>
		</div>
	);
}

export default EmployeesListItem;
