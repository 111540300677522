import { urlAlphabet } from 'nanoid';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const AdminRedirect = () => {

	const navigate = useNavigate()


	//console.log(window.location.href)
	//console.log(window.location.pathname)

	if (window.location.pathname === '/admin') {
		return (
			// <Navigate to={'/admin/afisha'} />
			<></>
		);
	}
	
}

export default AdminRedirect;
