import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.renartdev.ru/teatr-api';
	}

	//Добавить событие
	addEvent(data) {
		return axios.post(`${this._baseUrl}/events`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}
	//Удалить событие
	deleteEvent(id) {
		return axios.delete(`${this._baseUrl}/events/${id}`)
			.then(res => res)
			.catch(err => err)
	}
	//Получить спектакли
	getEvents(limit) {
		return axios.get(`${this._baseUrl}/events`, {
			params: {
				limit: limit
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить спектакли на период
	getEventsForAfisha(start, end) {
		return axios.get(`${this._baseUrl}/eventsForAfisha`, {
			params: {
				start: start,
				end: end
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить спектакли для главной страницы
	getEventForMainPage(limit) {
		return axios.get(`${this._baseUrl}/eventsForMainPage`, {
			params: {
				limit: limit
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить ближайшие события для главной страницы
	getClosestEventForMainPage(limit) {
		return axios.get(`${this._baseUrl}/closestEventsForMainPage`, {
			params: {
				limit: limit
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить событие по ID
	getEventForId(id) {
		return axios.get(`${this._baseUrl}/events/${id}`)
			.then(res => res)
			.catch(err => err)
	}
	//Изменить событие
	updateEvent(id, data) {
		return axios.post(`${this._baseUrl}/events/${id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

}

const EventAPI = new Api();

export default EventAPI;