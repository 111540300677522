import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.renartdev.ru/teatr-api';
	}

	//Добавить сотрудника
	addEmployee(data) {
		return axios.post(`${this._baseUrl}/employees`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}
	//Удалить сотрудника
	deleteEmployee(id, type) {
		return axios.delete(`${this._baseUrl}/employees/${id}`, {
			params: {
				type: type
			}
		})
			.then(res => res)
			.catch(err => err)
	}
	//Получить сотрудников
	getEmployee(type) {
		return axios.get(`${this._baseUrl}/employees`, {
			params: {
				type: type
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить сотрудников для сайта
	getEmployeeForSite(type) {
		return axios.get(`${this._baseUrl}/employeesSite`, {
			params: {
				type: type
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить сотрудника по ID
	getEmployeeForId(id, type) {
		return axios.get(`${this._baseUrl}/employees/${id}`, {
			params: {
				type: type
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить сотрудника по ID для сайта
	getEmployeeForIdSite(id, type) {
		return axios.get(`${this._baseUrl}/employeesSite/${id}`, {
			params: {
				type: type
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Изменить сотрудника
	updateEmployee(id, data) {
		return axios.post(`${this._baseUrl}/employees/${id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(res => res)
			.catch(err => err)
	}

	//Получить список по професси из команды
	getEmployeeDirector(profession) {
		return axios.get(`${this._baseUrl}/employees`, {
			params: {
				profession: profession
			}
		})
			.then(res => res)
			.catch(err => err)
	}

}

const EmployeesAPI = new Api();

export default EmployeesAPI;