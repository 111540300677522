import React from 'react';
import s from './ListItem.module.scss'
const inflection = require( 'inflection' )

const ListItem = ({ data }) => {
	return (
		<div className={s.line} >
			<div className={s.login}>{inflection.titleize(data?.login)}</div>
			<div className={s.fio}>{inflection.titleize(data?.fio)}</div>
		</div>
	);
}

export default ListItem;
