import axios from 'axios'

class Api {
	constructor() {
		this._baseUrl = 'https://teatrcdr.renartdev.ru/teatr-api';
	}

	//Получить ивенты с Ticketland
	getEventTicketland() {
		return axios.get(`${this._baseUrl}/ticketland`)
			.then(res => res)
			.catch(err => err)
	}
}

const TicketlandAPI = new Api();

export default TicketlandAPI;