import React from 'react';
import s from './UserForm.module.scss'
import TextField from '@mui/material/TextField';
import { useForm, Controller } from "react-hook-form";
import Button from '@mui/material/Button';
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from 'nanoid'
import UserAPI from '../../../../utils/UserAPI'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
const inflection = require('inflection')



const UserForm = ({ data }) => {
	// //console.log(data)
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const { id } = useParams()

	const navigate = useNavigate();

	const { control, handleSubmit, formState: { errors }, setError } = useForm({ mode: "onSubmit" });


	const checkData = (checkData) => {
		const result = (typeof checkData === 'object' &&
			!Array.isArray(checkData) &&
			checkData !== null)
			? true
			: false
		return result;
	}
	//Добавить пользователя
	const addUser = (data) => {
		data.userID = nanoid()
		//console.log(data)
		UserAPI.addUser(data)
			.then(result => {
				//console.log(result);
				if (result.status === 200) {
					navigate(-1)
				}

				if (result.response?.status === 406) {
					setError('login', { type: 'custom', message: 'Такой пользователь уже существует' });
					//console.log(result.response.data)
				}

				if (result.response?.status === 500) {
					//console.log(result.response.data)
				}
			})
	}
	//Изменить пользователя
	const updateUser = (data) => {
		//console.log(data)
		//console.log(id)

		UserAPI.updateUser(data, id)
			.then(result => {
				//console.log(result);
				if (result.status === 200 || result.status === 201) {
					navigate(-1)
				}

				if (result.response?.status === 404) {
					setError('login', { type: 'custom', message: 'Пользователь с таким id уже не существует' });
					//console.log(result.response.data)
				}

				if (result.response?.status === 406) {
					setError('login', { type: 'custom', message: 'Пользователь с таким логином уже существует' });
					//console.log(result.response.data)
				}

				if (result.response?.status === 500) {
					//console.log(result.response.data)
				}
			})
	}
	//Удалить пользователя
	const deleteUser = () => {
		//console.log(data)
		UserAPI.deleteUser(id)
			.then(result => {
				if (result.status === 200 || result.status === 201) {
					navigate(-1)
				}

				if (result.response?.status === 404) {
					setError('login', { type: 'custom', message: 'Пользователь с таким id уже не существует' });
					//console.log(result.response.data)
				}

				if (result.response?.status === 500) {
					//console.log(result.response.data)
				}
			})
	}

	//console.log(errors)

	return (
		<div className={s.container}>
			<form className={s.form} onSubmit={
				checkData(data) ? handleSubmit(updateUser) : handleSubmit(addUser)
			}>
				<Controller
				name="login"
					render={({ field }) => <TextField  {...field} variant="outlined" label="Логин*" size="small" fullWidth error={errors?.login?.message && true} helperText={errors?.login?.message ? errors?.login?.message : ' '} />}
					defaultValue={data ? data?.login : ''}
					rules={{
						required: 'Поле обязательно для заполнения',
						maxLength: { value: 15, message: 'Логин должен быть не более 15 символов' },
						pattern: { value: /[a-z0-9]/, message: 'Введите данне в правильном формате' }
					}}
					control={control}
				/>
				<div className={s.formGroup}>
					<Controller
						name="password"
						render={({ field }) =>
							<FormControl {...field} size="small" fullWidth variant="outlined" error={errors?.password?.message && true}>
								<InputLabel htmlFor="outlined-adornment-password">Пароль*</InputLabel>
								<OutlinedInput
									{...field}
									id="outlined-adornment-password"
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label="Пароль"
								/>
								<FormHelperText id="outlined-adornment-helper-text">{errors?.password?.message ? errors?.password?.message : ' '}</FormHelperText>
							</FormControl>
						}
						rules={{
							required: 'Поле обязательно для заполнения',
							minLength: { value: 8, message: 'Не менее 8 символов и состоит из строчных и прописных латинских буквы и цифры' },
							pattern: { value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, message: 'Не менее 8 символов и состоит из строчных и прописных латинских буквы и цифры' }
						}}

						defaultValue={data ? data?.password : ''}
						control={control}
					/>
					<Controller
						name="repeat_password"
						render={({ field }) =>
							<FormControl {...field} size="small" fullWidth variant="outlined" error={errors?.password?.message && true}>
								<InputLabel htmlFor="outlined-adornment-password2">Повторить пароль*</InputLabel>
								<OutlinedInput
									{...field}
									id="outlined-adornment-password2"
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label="Повторить пароль"
								/>
								<FormHelperText id="outlined-adornment-helper-text2">{errors?.password?.message ? errors?.password?.message : ' '}</FormHelperText>
							</FormControl>
						}
						rules={{
							required: 'Поле обязательно для заполнения',
							minLength: { value: 8, message: 'Не менее 8 символов и состоит из строчных и прописных латинских буквы и цифры' },
							pattern: { value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, message: 'Не менее 8 символов и состоит из строчных и прописных латинских буквы и цифры' }
						}}

						defaultValue={data ? data?.password : ''}
						control={control}
					/>
				</div>
				<Controller
					name="fio"
					render={({ field }) => <TextField {...field} variant="outlined" label="Описание*" size="small" fullWidth error={errors?.fio?.message && true} helperText={errors?.fio?.message ? errors?.fio?.message : ' '} />}
					rules={{
						required: 'Поле обязательно для заполнения',
						pattern: { value: /[^!@#$%^&*()_]+$/, message: 'Имя пользователя не может содержать спецсимволов' }
					}}
					control={control}
					defaultValue={data ? inflection.titleize(data?.fio) : ''}
				/>
				<div className={s.button}>
					{checkData(data) && <Button sx={{ borderRadius: '0px' }} variant="outlined" onClick={() => deleteUser(id)}>Удалить</Button>}
					<Button sx={{ borderRadius: '0px' }} variant="outlined" type='submit'>
						{checkData(data) ? <>Изменить</> : <>Добавить</>}
					</Button>
				</div>

			</form>
		</div >
	);
}

export default UserForm;
