import React from 'react';
import s from './ProjectPageSite.module.scss'
import MainTitle from '../../ui/MainTitle/MainTitle';
import HelmetUI from '../../ui/HelmetUI/HelmetUI';
import { Link, Routes, Route } from "react-router-dom";
import ProjectListSite from '../../components/ProjectSite/ProjectListSite/ProjectListSite';
import ProjectInformation from '../../components/ProjectSite/ProjectInformation/ProjectInformation';

const ProjectPageSite = () => {

	return (
		<div className={s.container}>
			<Routes>
				<Route index element={<HelmetUI title="Проекты - Центр драматургии и режиссуры" description="Театр Центр драматургии и режиссуры" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Проекты</MainTitle>} />
			</Routes>

			<div className={s.content}>
				<Routes>
					<Route index element={<ProjectListSite />} />
					<Route path=":id" element={<ProjectInformation />} />
				</Routes>
			</div>
		</div>

	);
}

export default ProjectPageSite;
