import React from 'react';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import { Link, Routes, Route } from "react-router-dom";
import Button from '@mui/material/Button';
import s from './Employees.module.scss'
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import EmployeesForm from '../../../components/Admin/Employees/EmployeesForm/EmployeesForm';
import EmployeesList from '../../../components/Admin/Employees/EmployeesList/EmployeesList';



const Employees = () => {
	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="Cотрудники" description="Настройка сотрудников театра" robots="none" />} />
				<Route path=":type/:id" element={<HelmetUI title="Редактирование" description="Редактирование сотрудника театра" robots="none" />} />
				<Route path="add" element={<HelmetUI title="Добавление" description="Добавление сотрудника в театр" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Сотрудники</MainTitle>} />
				<Route path=":type/:id" element={<MainTitle back>Редактирование</MainTitle>} />
				<Route path="add" element={<MainTitle back>Добавление</MainTitle>} />
			</Routes>

			<Routes>
				<Route index element={
					<div className={s.add}>
						<Link to='./add'>
							<Button sx={{ borderRadius: '0px' }} variant="outlined">Добавить</Button>
						</Link>
					</div>
				} />
			</Routes>


			<div className={s.content}>
				<Routes>
					<Route index element={<EmployeesList />} />
					<Route path=":type/:id" element={<EmployeesForm g1={true}/>} />
					<Route path="add" element={<EmployeesForm />} />
				</Routes>
			</div>
		</>
	);
}

export default Employees;
