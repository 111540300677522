import React, {useState, useEffect}from 'react';
import s from "./ProjectPage.module.scss"
import { Link, Routes, Route } from "react-router-dom";
import Button from '@mui/material/Button';
import MainTitle from '../../../ui/MainTitle/MainTitle';
import HelmetUI from '../../../ui/HelmetUI/HelmetUI';
import ProjectList from '../../../components/Admin/Project/ProjectList/ProjectList';
import ProjectForm from '../../../components/Admin/Project/ProjectForm/ProjectForm';

const ProjectPage = () => {

	return (
		<>
			<Routes>
				<Route index element={<HelmetUI title="Проекты" description="Управление проектами театра" robots="none" />} />
				<Route path="add" element={<HelmetUI title="Добавление проекта" description="Добавление новой проекта" robots="none" />} />
				<Route path=":id" element={<HelmetUI title="Редактирование проекта" description="Редактирование проекта" robots="none" />} />
			</Routes>

			<Routes>
				<Route index element={<MainTitle>Проекты</MainTitle>} />
				<Route path=":id" element={<MainTitle back>Редактировать</MainTitle>} />
				<Route path="add" element={<MainTitle back>Добавление проекта</MainTitle>} />
			</Routes>

			<Routes>
				<Route index element={
					<div className={s.add}>
						<Link to='./add'>
							<Button sx={{ borderRadius: '0px' }} variant="outlined">Добавить</Button>
						</Link>
					</div>
				} />
			</Routes>


			<div className={s.content}>
				<Routes>
					<Route index element={<ProjectList />} />
					<Route path="add" element={<ProjectForm />} />
					<Route path=":id" element={<ProjectForm g1={true} />} />
				</Routes>
			</div>
		</>
	);
}

export default ProjectPage;
